import React, { useState, useEffect } from 'react';

import * as StaffTrainingDtos from '../dtos/StaffTrainingDtos'
import { makeStyles, Theme, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { assessmentActions } from '../store/reducers/assessment';

interface IQuestionProps {
    question: StaffTrainingDtos.Question;
    questionNumber: number;
    attemptId: number;
    userResponse?: StaffTrainingDtos.UserQuestionResponse;
    forAssessment?: boolean;
}

interface IQuestionStyleProps {
}

const useStyles = makeStyles<Theme, IQuestionStyleProps>((theme: Theme) => ({
    root: {
        color: theme.palette.common.black
    },
    questionNumber: {
        marginRight: theme.spacing(1),
        fontWeight: "bold"
    },
    responses: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    text: {
        display: "flex",
        flexDirection: "row",
    }

}))


export const Question: React.FunctionComponent<IQuestionProps> = ({ question, questionNumber, attemptId, userResponse, forAssessment }) => {

    const classes = useStyles({});

    const dispatch = useDispatch();

    const [selected, setSelected] = useState<string>("");

    //const selected = userResponse && userResponse.responseId && question && question.responses ?
    //    question.responses.find(r => r.id === userResponse.responseId)?.value.toString() : "";

    useEffect(() => {
        if (userResponse && userResponse.responseId && question && question.responses)
            var qid = question.responses.find(r => r.id === userResponse.responseId)?.id;
            if (qid) {
                setSelected(qid.toString())
            }
        
    }, [])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (assessmentActions.updateQuestionResponse && question.id) {
            var userQuestionResponse: StaffTrainingDtos.UserQuestionResponse = {
                id: undefined,
                attemptId: attemptId,
                questionId: question.id,
                responseId: (+event.target.value)
            }

            if (userQuestionResponse) {
                dispatch(assessmentActions.updateQuestionResponse(userQuestionResponse));
            }

            setSelected((event.target as HTMLInputElement).value)
        }
    };

    const renderAssessmentQuestion = () => {
        return <FormControl component="fieldset">
            <div className={classes.text}>
                <div className={classes.questionNumber}>
                    {questionNumber + 1}.
                </div>
                <div
                    dangerouslySetInnerHTML={{ __html: question.text }}
                />
            </div>

            <RadioGroup className={classes.responses} aria-label="question" name={`question-${questionNumber}`} value={selected} onChange={handleChange}>
                {
                    question.responses?.map((response, index) => {
                        return (
                            <FormControlLabel
                                key={`response-${question.id}-${response.id}`}
                                value={response.id?.toString()}
                                control={<Radio />}
                                label={response.text}
                            />
                        )
                    })
                }


            </RadioGroup>
        </FormControl>
    }

    const renderResultsQuestion = () => {
        return <div>
            <div>{questionNumber + 1}. {question.text}</div>
            {
                question.responses?.map((response, index) => {
                    return <div key={`response-${question.id}-${response.id}`}>
                            {response.text}
                            </div>
                })
            }
        </div>
    }

    return (
        <div className={classes.root}>
            {
                forAssessment ?
                    renderAssessmentQuestion()
                    :
                    renderResultsQuestion()
            }
        </div>
    );
}

export default Question;