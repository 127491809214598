import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

interface IButtonProps {
    onClick?: string;
    className?: string;
    disable?: boolean;
    handleClick?: () => void;
}

interface IButtonStyleProps {
    disable?: boolean;
}

const useStyles = makeStyles<Theme, IButtonStyleProps>((theme: Theme) => ({
    component: {
        '&:hover': {
            cursor: (props: IButtonStyleProps) => props.disable ? "default" : "pointer"
        }
    }
}))


export const Button: React.FunctionComponent<IButtonProps> = ({ className, children, disable, handleClick }) => {

    const classes = useStyles({ disable: disable });

    return (
        <div
            className={classNames(classes.component, className)}
            onClick={disable ? () => { } : handleClick }
        >
            {children}
        </div>
    );
}

export default Button;