/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains all the public asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to allow for the use of function component.
 */
import * as React from 'react';

import { IRoute } from '..';


/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


export const AsyncAdminWrapperComponent = React.lazy(() => import('../../pages/Administration/Wrapper'));

export const AsyncAdminHomeComponent = React.lazy(() => import('../../pages/Administration/Home'));

export const AsyncAdminUserModulesComponent = React.lazy(() => import('../../pages/Administration/UserModules'));

export const AsyncAdminTrainingAssignmentComponent = React.lazy(() => import('../../pages/Administration/TrainingAssignment'));

const adminRoute: IRoute = {
    component: AsyncAdminWrapperComponent,
    path: '/administration',
    requiresAuthentication: true,
    childRoutes: {
        home: {
            component: AsyncAdminHomeComponent,
            path: '/administration/home'
        },
        modules: {
            component: AsyncAdminUserModulesComponent,
            path: '/administration/user-modules'
        },
        assignment: {
            component: AsyncAdminTrainingAssignmentComponent,
            path: '/administration/training-assignment'
        },
    }
}

export default adminRoute;