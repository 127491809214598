/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains helper methods for an authenticated person object.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */
import * as StaffTraining from '../dtos/StaffTrainingDtos';

/*
 * ------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------
 */
export const TIME_LIMIT_FOR_RESUME: number = 5;

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/**
 * This method checks if an authenticated person has a role.
 * @param moduleId The module to check
 * @param attemptedModules The list of attempted modules
 * @returns returns remaing number of attempts for a given module
 */
export const attemptsRemaining = (attemptedModules: StaffTraining.UserModuleAttempt[], outstandingModules: StaffTraining.UserModuleAttempt[]): boolean => {

    let attempts: number = 0;

    outstandingModules.forEach(userModule => {
        attempts = 0;

        if (userModule?.module?.rules) {
            attempts = attempts + (userModule.module.rules.numberOfAttempts ? userModule.module.rules.numberOfAttempts : 0);

            if (attempts > 0) {
                attemptedModules.forEach(attempt => {
                    if (userModule?.module?.rules && attempt.moduleId === userModule.module?.id && attemptFromCurrentRuleSet(attempt, userModule.module.rules) && (userModule?.finishTime && !withinTimeRange(userModule?.finishTime, TIME_LIMIT_FOR_RESUME))) {
                        attempts--;
                    }
                })
            }
        }
        

        
    })

    return attempts > 0 ? true : false;
}

const attemptFromCurrentRuleSet = (attempt: StaffTraining.UserModuleAttempt, rules: StaffTraining.ModuleRules) => {
    let finishTime = new Date(attempt.finishTime ? attempt.finishTime.toString() : "").valueOf();
    let rulesStartDate = new Date(rules.startDate ? rules.startDate.toString() : "").valueOf();
    let rulesDepricatedDate = new Date(rules.deprecatedDate ? rules.deprecatedDate.toString() : "1/1/3000").valueOf();

    return (finishTime >= rulesStartDate && finishTime <= rulesDepricatedDate)
}

export const attemptNumber = (attemptedModules: StaffTraining.UserModuleAttempt[], outstandingModule: StaffTraining.UserModuleAttempt): number => {

    let attempts: number = 1;

    
    if (outstandingModule?.module?.rules) {
        attemptedModules.forEach(attempt => {
            if (outstandingModule?.module?.rules && attempt.moduleId === outstandingModule.moduleId && attemptFromCurrentRuleSet(attempt, outstandingModule.module.rules)) {
                attempts++;
            }
        })
    }

    return attempts;
}



export const timeLimit = (outsandingModules: StaffTraining.UserModuleAttempt[]): number => {
    let timeLimit: number = 0;

    outsandingModules.forEach(om => {
        timeLimit += om.module?.rules?.minutesAllowed ? om.module.rules.minutesAllowed * 60 : 0;
    })

    let startTimeString = outsandingModules[0].startTime;
    

    if (startTimeString) {
        let startTime = Math.floor(new Date(startTimeString).getTime() / 1000);
        let currentTime = Math.floor((new Date()).getTime() / 1000);
        if (startTime) {
            timeLimit = timeLimit - ( currentTime - startTime);
        }
    }

    if (timeLimit < 0) {
        timeLimit = 0;
    }

    return timeLimit;
}

export const initialTimeLimit = (outsandingModules: StaffTraining.UserModuleAttempt[]): number => {
    let timeLimit: number = 0;

    outsandingModules.forEach(om => {
        timeLimit += om.module?.rules?.minutesAllowed ? om.module.rules.minutesAllowed * 60 : 0;
    })


    return timeLimit;
}

export const allQuestionsAnswered = (userModules: StaffTraining.UserModuleAttempt[]): boolean => {

    let responseQuestionIds: number[] = [];
    let questionIds: number[] = [];

    for (let i = 0; i < userModules.length; i++) {
        if (userModules[i] && userModules[i].module) {
            let questions = userModules[i].module?.questions
            if (questions) {
                for (let j = 0; j < questions.length; j++) {
                    let questionId = questions[j].id;
                    if (questionId) {
                        questionIds.push(questionId);
                    }
                }
            }

            let responses = userModules[i].userQuestionResponses;
            if (responses) {
                for (let j = 0; j < responses.length; j++) {
                    let questionId = responses[j].questionId;
                    if (questionId && responses[j].responseId !== 0) {
                        responseQuestionIds.push(questionId);
                    }
                }
            }
        }
    }

    return arrayEquals(questionIds.sort(), responseQuestionIds.sort());
}

const arrayEquals = (questions: number[], responses: number[]): boolean => {
    return Array.isArray(questions) &&
        Array.isArray(responses) &&
        questions.length === responses.length &&
        questions.every((val, index) => val === responses[index]);
}

export const modulePassed = (userModule: StaffTraining.UserModuleAttempt): boolean => {
    let passed = false;

    if (userModule && userModule.score && userModule.module && userModule.module.rules) {
        passed = ((userModule.score / userModule.module?.rules?.numberOfQuestions) * 100 >= userModule.module?.rules?.passMark);
    }

    return passed;
}

const withinTimeRange = (date: string, minutes: number): boolean => {

    let inputDate = new Date(date);
    let currentDate = new Date();

    return (inputDate.getTime() + (minutes * 60 * 1000)) > currentDate.getTime();
}

export const timeToRetry = (startDate: string, finishDate: string, timeAllowed: number): number => {

    let startTime = new Date(startDate).getTime();
    let limitTime = startTime + (timeAllowed * 60 * 1000);
    let finishTime = new Date(finishDate).getTime();
    let currentTime = new Date().getTime();

    if (limitTime - currentTime > 0 && limitTime - currentTime < (TIME_LIMIT_FOR_RESUME * 60 * 1000)) {
        return Math.floor((limitTime - currentTime) / 1000);
    }
    else if (limitTime - currentTime <= 0) {
        return 0;
    }
    else {
        return Math.floor((finishTime + (TIME_LIMIT_FOR_RESUME * 60 * 1000) - currentTime) / 1000);
    }
}

export const isOverdue = (inputDate: string) => {
    let dueDate = new Date(inputDate);
    let currentDate = new Date();

    let overdue = false;

    if (currentDate > dueDate)
        overdue = true;

    return overdue;
}