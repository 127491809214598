/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file combines all the asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load components asychronously.
 */
import { IRoute, } from '..';

/*
 * Used for typing:
 *      -IDictionary 
 */
import { IDictionary } from '../../types/IDictionary';

/*
 * Used to get access to the public routes. 
 */
import staffRoute from './staff';
import adminRoute from './administration';
import commonRoute from './common';


/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const routes: IDictionary<IRoute> =
{
    staff: staffRoute,
    admin: adminRoute,
    common: commonRoute
};

export default routes;