import React from 'react';

import * as StaffTrainingDtos from  '../dtos/StaffTrainingDtos'
import { makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

interface IUserModuleProps {
    outstandingModules: StaffTrainingDtos.UserModuleAttempt[];
}

interface IUserModuleStyleProps {
    lastModule?: boolean | null;
}

const useStyles = makeStyles<Theme, IUserModuleStyleProps>((theme) => ({
    root: {
        width: "100%"
    },
    question: {
        margin: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    trainingInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginBottom: theme.spacing(6)
    },
    modulesContaininer: {
        boxShadow: "0 0 5px 1px #0000004a",
        width: "80%",
    },
    modulesTable: {
        borderCollapse: "collapse",
        width: "100%",
        "& tbody": {
            display: "block",
            paddingBottom: theme.spacing(2)
        }
    },
    modulesTableRow: {
        display: "flex",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        justifyContent: "space-evenly",
        "& td": {
            minWidth: "25%",
            textAlign: "center",
            fontSize: "14pt"
        },
    },
    modulesTableHeader: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        margin: theme.spacing(2),
        display: "flex",
        fontWeight: "bold",
        justifyContent: "space-evenly",
        "& td": {
            minWidth: "25%",
            textAlign: "center",
            fontSize: "14pt"
        }
    },
    leftColumn: {
        width: "50%"
    },
    summaryHeader: {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        margin: "32px 0"
    },
    module: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "8px 32px 8px 32px",
        border: "solid 1px",
        "& span": {
            fontSize: "20pt",
            fontWeight: "normal"
        }
    },
    moduleHeader: {
        width: "45%",
        backgroundColor: theme.palette.common.white,
        border: "none",
        color: theme.palette.grey[600],
    },
    greyRow: {
        backgroundColor: theme.palette.grey[200],
    },
}))

export const OutstandingModules: React.FunctionComponent<IUserModuleProps> = ({ outstandingModules }) => {


    const classes = useStyles({})

    return (
        <div className={classes.root}>
            {
                <div className={classes.trainingInfo}>
                    <div className={classes.summaryHeader}>
                        Outstanding Training Modules
                    </div>

                    <div className={classes.modulesContaininer}>
                        <table className={classes.modulesTable}>
                            <tbody>
                                <tr className={classNames(classes.modulesTableHeader, classes.greyRow)}>
                                    <td className={classes.leftColumn}>
                                        Course
                                    </td>
                                    <td>
                                        Questions
                                    </td>
                                    <td>
                                        Due Date
                                    </td>
                                </tr>
                                {
                                    outstandingModules && outstandingModules.length > 0 ?
                                        outstandingModules.map((m, index) => {
                                            return <tr key={`outstandingModule-${index}`} className={classes.modulesTableRow}>
                                                <td className={classes.leftColumn}>{m.module?.name}</td>
                                                <td>{m.module?.rules?.numberOfQuestions}</td>
                                                <td>{m.dueDate ? new Date(m.dueDate).toLocaleDateString('en-GB') : ""}</td>
                                            </tr>
                                        })
                                        : null

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
        );
}

export default OutstandingModules;