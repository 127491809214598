/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file defines more correctly typed redux-act createAction() calls.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createAction as reduxActCreateAction, PayloadReducer, ComplexActionCreator } from 'redux-act';
import * as Redux from 'redux'

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ReplaceReturnType } from '../types/HelperTypes';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IAnyFunction {
    (...args: any[]): any
}

declare module 'redux-act' {
    interface Reducer<S, A extends Redux.Action = Redux.AnyAction> {
        on<P, M={}>(actionCreator: (...args: any[]) => Action<P>, handler: Handler<S, P, M>): Reducer<S>
    }
}

const createAction = <
    FunctionType extends IAnyFunction,
    U extends ReplaceReturnType<
        FunctionType,
        {
            type: string,
            payload: ReturnType<FunctionType>,
            meta: {}
        }
    > & ComplexActionCreator<ReturnType<FunctionType>, {}>
>(type: string, action: FunctionType): U => {
    const payloadReducer: PayloadReducer<ReturnType<FunctionType>> = action;

    return reduxActCreateAction(type, payloadReducer) as any;
}

export default createAction;