import React, { useEffect } from 'react';
import { Switch, RouteComponentProps, Route, Redirect } from 'react-router-dom'
import { createRoutes } from '../../routes';
import routes from '../../routes/routes';
import { makeStyles, Theme, Link, Tooltip, Button } from '@material-ui/core';
import { MenuBar } from '../../components/MenuBar';
import { Footer } from '../../components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { IStoreState } from '../../store/createStore';
import { personActions } from '../../store/reducers/person';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { routerActions } from 'connected-react-router';
import { Link as MyLink } from './../../components/Link';
import { faHome, faClipboardList, faClipboardCheck, faTasks } from '@fortawesome/free-solid-svg-icons';
import Assessment from './Assessment';
import Home from './Home';
import Results from './Results';


interface IWrapperrProps extends RouteComponentProps {
}

interface IWrapperStyleProps {

}

const bctLogo = require("../../assets/images/bctLogo.png");

const useStyles = makeStyles<Theme,IWrapperStyleProps> ((theme: Theme) => ({

    main: {
        backgroundColor: theme.palette.grey[200],
        height: "100vh",
        width: "100%",
        display: 'flex',
        flexDirection: 'column'
    },

    header: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        paddingLeft: "17%",
        paddingRight: "17%",
        justifyContent: "space-between",
        backgroundColor: "#FFFFFF"
    },

    headerImageContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& h1": {
            color: theme.palette.primary.main,
            fontSize: "32px",
            fontWeight: "700",
            marginTop: "0",
            marginBottom: "0",
            marginLeft: theme.spacing(4)
        }
    },
    image: {
        width: theme.spacing(17)
    },
    headerTextContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        fontFamily: "Roboto, sans-serif",
        justifyContent: "center",
    },

    body: {
        width: "100%"
    },
    userName: {
        fontSize: "20px"
    },
    link: {
        marginLeft: "10px",
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.secondary.main
        }
    },
    "MuiTooltip-tooltip": {
            fontSize: "20em",
    }
}))

const Wrapper: React.FunctionComponent<IWrapperrProps> = (props) => {

    const { location } = props;

    const classes = useStyles({});
    const authenticatedPerson = useSelector((state: IStoreState) => state.personState.authenticatedPerson)

    const dispatch = useDispatch();

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <div className={classes.headerImageContainer}>
                    <img className={classes.image} alt="BCT logo" src={bctLogo} />
                    <h1>Staff Training</h1>
                </div>
                <div className={classes.headerTextContainer}>
                    <div className={classes.userName}>
                        {
                            authenticatedPerson ?
                                `${authenticatedPerson.firstName} ${authenticatedPerson.surname}`
                                : null
                        }
                        &nbsp; |
                        <Tooltip title="Home" aria-label="add" placement="bottom" >
                            <Link className={classes.link}
                                href={routes.staff.childRoutes?.home.path}
                            >
                                <span className={classes.icon}><FontAwesomeIcon icon={faHome} fixedWidth /></span>
                            </Link>
                        </Tooltip>
                        &nbsp; |
                        <Tooltip title="Results" aria-label="add" placement="bottom" >
                            <Link className={classes.link}
                                href={`${routes.staff.childRoutes?.home.path}#completedTraining`}
                            >
                                <span className={classes.icon}><FontAwesomeIcon icon={faClipboardList} fixedWidth /></span>
                            </Link>
                        </Tooltip>
                        {
                            authenticatedPerson?.isAdmin && (
                                <>
                                &nbsp; |
                                <Tooltip title="Admin Portal" aria-label="add" placement="bottom" >
                                    <Link className={classes.link}
                                        href={routes.admin.childRoutes?.home.path}
                                    >
                                        <FontAwesomeIcon icon={faUserCog} fixedWidth />
                                    </Link>
                                </Tooltip>
                                </>)
                        }
                        &nbsp; |
                        <Tooltip title="Log out" aria-label="add" placement="bottom" >
                            <Link className={classes.link}
                                href="/auth/logout"
                            >
                                <FontAwesomeIcon  icon={faSignOutAlt} fixedWidth />
                            </Link>
                        </Tooltip>
                    </div>
                </div>
                
            </div>
            <MenuBar
                admin={false}
            />
            <Switch>
                <Route path="/staff/home" component={Home} />
                <Route path={['/staff/assessment/:token', '/staff/assessment']} component={Assessment} />
                <Route path="/staff/results" component={Results} />
                <Redirect path="/staff" to="/staff/home" exact />
            </Switch>
            <Footer />
        </div>
    );
}

export default Wrapper;