import React from 'react';

import * as StaffTrainingDtos from  '../dtos/StaffTrainingDtos'
import { makeStyles, Theme } from '@material-ui/core';
import Question from '../components/Question';
import classNames from 'classnames';

interface IModuleProps {
    moduleAttempt: StaffTrainingDtos.UserModuleAttempt;
}

interface IModuleStyleProps {
    disable?: boolean;
}

const useStyles = makeStyles<Theme, IModuleStyleProps>((theme: Theme) => ({
    question: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(1)
    },
    greyRow: {
        backgroundColor: theme.palette.grey[100]
    },
}))

export const Module: React.FunctionComponent<IModuleProps> = ({ moduleAttempt }) => {

    const classes = useStyles({});

    const module = moduleAttempt && moduleAttempt.module ? moduleAttempt.module : null;

    return (
        <div className={classes.root}>
            {
                module && module.questions && module.questions.length > 0 ?
                    <div>
                        {
                            module.questions.map((question, index) => {
                                let selectedResponse = moduleAttempt.userQuestionResponses ?
                                    moduleAttempt.userQuestionResponses.find(r => r.questionId === question.id)
                                    : undefined;
                                return (
                                    <div key={`question-${index}`} className={classNames(classes.question, index % 2 ? classes.greyRow : null)}>
                                        <Question
                                            question={question}
                                            questionNumber={index}
                                            attemptId={moduleAttempt.id ? moduleAttempt.id : 0}
                                            userResponse={selectedResponse}
                                            forAssessment={true}
                                        />
                                    </div>
                                    )
                                
                            })
                        }
                    </div>
                    : null
            }
        </div>
    );
}

export default Module;