import * as React from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';

interface IConditionalRedirectProps extends RedirectProps {
    enabled?: boolean;
}

const ConditionalRedirect: React.FunctionComponent<IConditionalRedirectProps> = ({
    enabled,
    ...redirectProps
}) => {
    if (!enabled) {
        return null;
    }

    return (
        <Redirect {...redirectProps} />
    );
};

export default ConditionalRedirect;