import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface IConditionalRouteProps extends RouteProps {
    enabled?: boolean;
}

const ConditionalRoute: React.FunctionComponent<IConditionalRouteProps> = ({
    enabled,
    ...routeProps
}) => {
    if (!enabled) {
        return null;
    }

    return (
        <Route {...routeProps} />
    );
};

export default ConditionalRoute;