import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { routerActions } from 'connected-react-router';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

interface ILinkProps {
    to?: string;
    hash?: string;
    newTab?: boolean;
    push?: typeof routerActions.push;
    className?: string;
    disable?: boolean;
}

interface ILinkStyleProps {
    disable?: boolean;
}

const useStyles = makeStyles<Theme, ILinkStyleProps>((theme: Theme) => ({
    component: {
        '&:hover': {
            cursor: (props: ILinkStyleProps) => props.disable ? "default" : "pointer"
        }
    }
}))


export const Link: React.FunctionComponent<ILinkProps> = ({ to, hash, className, newTab, children, disable }) => {

    const dispatch = useDispatch();

    const classes = useStyles({ disable: disable });
    const location = useLocation();

    const toRoute = to ? hash ? `${to}#${hash}` : to : "/"

    const handleClick = (event: { preventDefault: () => void; }) => {
        if (to !== location.pathname) {
            if (routerActions.push && !disable) {
                dispatch(routerActions.push(toRoute))
            }
            event.preventDefault();
        }
    }

    return (
        <a
            href={!disable ? toRoute : "/"}
            className={classNames(classes.component, className)}
            target={newTab ? '_blank' : undefined}
            onClick={!disable ? handleClick : () => { return false }}
        >
            {children}
        </a>
    );
}

export default Link;