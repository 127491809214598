
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains all the asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to allow for the use of function component.
 */
import * as React from 'react';

/*
 * Used for typings
 */
import { RouteComponentProps, Route, Redirect } from 'react-router';

import { Location } from 'history';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typing:
 *      -IDictionary 
 */
import { IDictionary } from '../types/IDictionary';

/*
 * Used to get access to the API types and requests
 */
import * as StaffTrainingDtos from '../dtos/StaffTrainingDtos';
/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IRoute<LinkParams = any> {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    path: string;
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
    childRoutes?: IDictionary<IRoute>;
    redirect?: string;

    /**
     * The roles required to access the route (OR condition).
     */
    requiresAuthentication?: boolean;

    /**
     * Admin role is required for access
     */
    requiresAdmin?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const loading = <div>Loading ...</div>;
//    return null;
//};

export const delay = 500;

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const createRoutes = (location: Location,
    authenticatedPerson?: StaffTrainingDtos.Person,
    routes?: IDictionary<IRoute>) => {

    return (

        routes ?
            Object
                .keys(routes)
                .map(key => {
                    const route = routes[key];

                    //if route needs the user to be logged in.
                    if (route.requiresAuthentication) {

                        //if no user logged in redirect to login.
                        if (!authenticatedPerson) {
                            const redirectLink = encodeURIComponent(`${location.pathname}${location.search}${location.hash}`);
                            const loginLink = `/auth/openiddict?redirect=${redirectLink}`

                            window.location.href = loginLink;
                        }

                        //if the route requires admin.
                        if (route.requiresAdmin) {

                            // if the logged in user does not have those roles redirect to 403.
                            if (!authenticatedPerson ||
                                !authenticatedPerson.isAdmin) {
                                return <Redirect
                                    key={key}
                                    to={routes.staff.childRoutes?.home.path!}
                                    path={route.path}
                                    exact={route.exact}
                                    strict={route.strict}
                                />
                            }
                        }
                    }

                    if (route.redirect) {
                        return (
                            <Redirect
                                key={key}
                                to={route.redirect}
                                path={route.path}
                                exact={route.exact}
                                strict={route.strict}
                            />
                        );
                    }

                    //If all authentication requirements pass, route to component.
                    return (
                        <Route
                            key={key}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                            sensitive={route.sensitive}
                            strict={route.strict}
                            />
                    );
                })

            : null
    )
}