/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains functions to initialise the redux store.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create the router reducer and middleware.
 */
import {
    connectRouter,
    routerMiddleware as createRouterMiddleware,
    RouterState
} from 'connected-react-router';

/*
 * Used to type objects:
 *      - History
 */
import { History } from 'history';

/*
 * Used to create the redux store and attach the required middleware.
 */
import {
    createStore,
    combineReducers,
    applyMiddleware,
    compose,
    DeepPartial
} from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to attach new reducers and logic as they are registered in the registry.
 */
import { combinedReducers, combinedEpics } from './reducers';
import { createEpicMiddleware } from 'redux-observable';
import { IModuleState } from './reducers/module';
import { IUserModuleState } from './reducers/userModule';
import { IPersonState } from './reducers/person';
import { IAssessmentState } from './reducers/assessment';


export interface IStoreState extends
    IModuleState,
    IUserModuleState,
    IPersonState,
    IAssessmentState{ };

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/**
 * This function initialises the redux store and its associated middleware.
 * @param history The history to be used with the router middleware.
 * @param preloadState The preloaded state from the server.
 * @returns the store and logic middleware.
 */
export const createReduxStore = (
    history: History,
    preloadState: DeepPartial<IModuleState & IPersonState & IAssessmentState & IUserModuleState & { router: RouterState }>
) => {
    // Create router middleware with the provided history.
    const routerMiddleware = createRouterMiddleware(history);

    // Create logic middleware with the currently registered logic.
    const epicMiddleware = createEpicMiddleware();
   
    // Create middleware
    let middleware = null;

    if (process.env.NODE_ENV === 'production') {
        middleware = applyMiddleware(routerMiddleware, epicMiddleware,);
    }
    else {
        if (process.env.NODE_TARGET === 'server') {
            middleware = applyMiddleware(routerMiddleware, epicMiddleware);
        } else {
            const logger = require('redux-logger').default;

            middleware = applyMiddleware(routerMiddleware, epicMiddleware, logger);
        }
    }
    
    const composeEnhancers = process.env.NODE_TARGET !== 'server' &&
        process.env.NODE_ENV !== 'production' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 500 }) :
        compose;

    const reducer = combineReducers(
        {
            router: connectRouter(history),
            ...combinedReducers
        });

    // Create store
    const store = createStore(reducer, preloadState, composeEnhancers(middleware));

    epicMiddleware.run(combinedEpics);

    return store;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default createReduxStore;
