import { makeStyles, Theme, TableFooter, IconButton, Collapse, Box, TablePagination, LinearProgress, Chip, FormControl, Select, InputLabel, Input, MenuItem, Checkbox, ListItemText, FormControlLabel, Tooltip, Button } from "@material-ui/core";
import React, { useEffect, useCallback, useMemo, useState, HTMLAttributes } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Paper from '@material-ui/core/Paper';
import { UserModuleAttempt, Person, UserQuestionResponse, Question, QuestionResponse } from "../../dtos/StaffTrainingDtos";
import { useSelector, useDispatch } from "react-redux";
import { IStoreState } from "../../store/createStore";
import { personActions } from "../../store/reducers/person";
import { userModuleActions } from "../../store/reducers/userModule";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { modulePassed } from "../../utils/userModule";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { RequestState } from "../../types/RequestState";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import { useLocation } from 'react-router';
import globalStyles from "../../styles/globalStyles";
import { useDeferredValue } from '../../utils/defferedValue';
import { moduleActions } from "../../store/reducers/module";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IUserModulesProps {
}

interface IUserModulesStyleProps {
}

const useStyles = makeStyles<Theme, IUserModulesStyleProps>((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[200],
        display: "flex",
        justifyContent: "center",
        fontFamily: 'Roboto, sans-serif',
        paddingBottom: theme.spacing(2),
        flex: '1 1 auto'
    },
    body: {
        backgroundColor: "#FFFFFF",
        width: "70%",
        boxShadow: "0 0 5px 1px #0000004a",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "min-content",
        [theme.breakpoints.down('sm')]: {
            width: "max-content"
        }
    },
    resultsContaininer: {
        width: "90%",
        textAlign: "center",
        "& .MuiTableContainer-root": {
            boxShadow: "0 0 5px 1px #0000004a"
        },
        marginBottom: theme.spacing(8),
        marginTop: theme.spacing(4)
    },
    pass: {
        color: theme.palette.success.main
    },
    fail: {
        color: theme.palette.error.dark
    },
    summaryHeader: {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        margin: "32px 0"
    },
    reportForm: {
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(8),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    selectors: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        "& .MuiSelect-select.MuiSelect-select": {
            minWidth: "390px",
            textAlign: "left",
        },
        "& .MuiInputLabel-formControl": {
            position: "relative",
            marginBottom: "20px"
        },
        "& .MuiInputLabel-shrink": {
            transform: "none"
        },
        "& .MuiInputLabel-animated": {
            transition: "none"
        }
    },
    selection: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        "& .MuiSelect-select.MuiSelect-select": {
            minWidth: "390px",
        },
        "& .MuiInputLabel-formControl": {
            position: "relative",
            marginBottom: "20px"
        },
        "& .MuiInputLabel-shrink": {
            transform: "none"
        },
        "& .MuiInputLabel-animated": {
            transition: "none"
        },
        "& .MuiSelect-select": {
            "&:hover": {
                curser: "default",
            },
        },
    },
    formBottom: {
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
    },
    inputRoot: {
        "& .MuiInputBase-root": {
            maxWidth: "420px",
            "& select": {
                "& option": {
                    paddingLeft: "10px"
                }
            }
        }
    },
    responses: {
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(4)
    },
    repsonsesBody: {
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
            '&:last-child td': {
                borderBottom: 0,
            },
        },
    },
    questionTable: {
        "& .MuiTableCell-root": {
            borderBottom: "1px solid black",
        },
    }
    
}))

const Row = (props: { attempt: UserModuleAttempt, person?: Person, prevUserName: string, handleClick: Function }) => {
    const { attempt, person, prevUserName, handleClick } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles({});

    const passed = (userModule: UserModuleAttempt): boolean => {
        return modulePassed(userModule);
    }

    const isCorrectResponse = (response: UserQuestionResponse | undefined, question: Question): boolean => {
        let result = false
        if (question.responses.find(r => r.id === response?.responseId && r.questionId == question.id)?.value
            === question.responses.find(r => r.value === question.correctResponse && r.questionId == question.id)?.value) {
            result = true;
        }
        return result;
    }
    const encodeResponse = (value?: number) => {
        let encoding = ""
        switch (value) {
            case 1:
                encoding = "A";
                break;
            case 2:
                encoding = "B";
                break;
            case 3:
                encoding = "C";
                break;
            case 4:
                encoding = "D";
                break;
            default:
                break;
        }
        return encoding;
    }

    const getResponseClass = (response: QuestionResponse, userResponse: UserQuestionResponse | undefined, question: Question) => {
        let className = "";

        if (!userResponse || (userResponse && userResponse.responseId == 0) || (response.id == userResponse?.responseId && !isCorrectResponse(userResponse, question))) {
            className = classes.fail;
        }

        if (response.value == question.correctResponse) {
            className = classes.pass;
        }

        return className;
    }

    return (
        <React.Fragment>
            <TableRow className={classes.row}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.headerSize} align="left" component="th" scope="row">
                    {
                        prevUserName !== attempt.userName ?
                            `${person?.firstName} ${person?.surname}`
                        : null        
                    }
                </TableCell>
                <TableCell className={classes.headerSize} align="center" component="th" scope="row">
                    {attempt.module.name}
                </TableCell>
                <TableCell className={classes.headerSize} align="center">
                    {
                        attempt.finishTime ?
                            new Date(attempt.finishTime).toLocaleDateString('en-GB')
                        : null        
                    }
                </TableCell>
                <TableCell className={classes.headerSize} align="center" component="th" scope="row">
                    {Math.ceil(attempt.module.rules.passMark / 100 * attempt.module.rules.numberOfQuestions)}
                </TableCell>
                <TableCell className={classes.headerSize} align="center" component="th" scope="row">
                    { attempt.score }
                </TableCell>
                <TableCell className={classes.headerSize} align="center">
                    <FontAwesomeIcon className={passed(attempt) ? classes.pass : classes.fail} icon={passed(attempt) ? faCheck : faTimes} fixedWidth />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} marginBottom={3}>
                            <Typography variant="h6" gutterBottom component="div">
                                Responses
                            </Typography>
                            <Table size="small" aria-label="courses" className={classes.questionTable}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classNames(classes.headerWeight, classes.headerSize)}>Question</TableCell>
                                        <TableCell className={classNames(classes.headerWeight, classes.headerSize)} style={{ textAlign: "center", width: "60px" }}>Id</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {attempt.module.questions.map((question, index) => (
                                        <TableRow key={`userModule-${attempt.id}-${question.id}`}>
                                            <TableCell>
                                                <Table>
                                                    <TableBody className={classes.repsonsesBody}>
                                                        <TableRow>
                                                            <TableCell component="td" scope="row" style={{ width: "30px", verticalAlign: "top" }}>
                                                                {index+1}.
                                                            </TableCell>
                                                            <TableCell component="td" scope="row">
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: question.text }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key={`response-options-${attempt.id}-${question.id}`}>
                                                            <TableCell colSpan={2}>
                                                                <div className={classes.responses}>
                                                                {
                                                                        question.responses.map(response => (
                                                                            <span key={`response-${response.id}`} className={getResponseClass(response, attempt.userQuestionResponses.find(ur => ur.questionId === question.id), question)}>
                                                                                {response.text}
                                                                            </span>
                                                                    ))
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                            <TableCell component="td" scope="row" style={{ textAlign: "center" }}>
                                                {question.id}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )

}

const UserModules: React.FunctionComponent<IUserModulesProps> = () => {

    const classes = useStyles({});
    const globalClass = globalStyles({});

    const modules = useSelector((state: IStoreState) => state.moduleState.data);

    const completedModules = useSelector((state: IStoreState) => state.userModuleState.completedModules);
    const peopleWithTraining = useSelector((state: IStoreState) => state.personState.peopleWithTraining);
    const loadCompletedState = useSelector((state: IStoreState) => state.userModuleState.loadCompletedState);
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPersons, setSelectedPersons] = useState<string[]>([])
    const [selectedModules, setSelectedModules] = useState<number[]>([])
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null)
    const [failed, setFailed] = useState<boolean>(false);
    const [selectedResponse, setSelectedResponse] = useState<QuestionResponse | undefined>(undefined);
    const [correctResponse, setCorrectResponse] = useState<QuestionResponse | undefined>(undefined);

    const handleStartDateChange = (date: Date | null) => {
        if (!!date) {
            if ((!selectedEndDate || date.getTime() <= selectedEndDate.getTime())) {
                setSelectedStartDate(date);
            }
            else if (!!selectedEndDate) {
                date = new Date();
                date.setDate(selectedEndDate.getDate() - 1);
                setSelectedStartDate(date);
            }
        }
    };

    const handleResponseClick = (selectedResponse: QuestionResponse, correctResponse: QuestionResponse) => {
        setSelectedResponse(selectedResponse);
        setCorrectResponse(correctResponse);
    }

    const handleClose = () => {
        setSelectedResponse(undefined);
        setCorrectResponse(undefined);
    }

    const handleEndDateChange = (date: Date | null) => {
        if (!!date) {
            if ((!selectedStartDate || date.getTime() >= selectedStartDate.getTime())) {
                setSelectedEndDate(date);
            }
            else if (!!selectedEndDate) {
                date = new Date();
                date.setDate(selectedStartDate.getDate() + 1);
                setSelectedEndDate(date);
            }
        }
    };

    const handleFailedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFailed(event.target.checked);
    };

    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, completedModules?.length - page * rowsPerPage);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    useEffect(() => {
        dispatch(personActions.loadWithTraining(null, true, null, false))
        dispatch(moduleActions.load());
        
        return () => {
            dispatch(personActions.clear())
            dispatch(userModuleActions.clear())
        }
    }, [])

    const handleRunClick = () => {
        dispatch(userModuleActions.loadAllCompleted(
            true,
            failed,
            selectedPersons,
            selectedModules,
            selectedStartDate?.toISOString(),
            selectedEndDate?.toISOString()
            ))
    };

    const handlePeopleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedPersons(event.target.value as string[]);
    };

    const handleModuleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedModules(event.target.value as number[]);
    };

    const getUser = (userName: string) => {
        return peopleWithTraining?.filter(p => p.userName === userName)[0];
    }

    const exportUrl = useMemo(() => {
        let userNames: string[] = [];
        if (selectedPersons?.length > 0) {
            userNames = selectedPersons;
        }
        let userNameString: string = userNames.join(',');
        let moduleIds: number[] = [];
        if (selectedModules?.length > 0) {
            moduleIds = selectedModules;
        }
        let moduleIdsString: string = moduleIds.join(',');
        return `/api/userModules-complete-export?FailOnly=${failed == true}&UserNames=${userNameString}&ModuleIds=${moduleIdsString}&StartDate=${selectedStartDate?.toISOString()}&EndDate=${selectedEndDate?.toISOString()}`;
    }, [selectedPersons, selectedModules, selectedStartDate, selectedEndDate, failed]);

    const renderCompleted = () => {
        return (
                <div className={classes.resultsContaininer}>
                    <div className={classes.summaryHeader}>
                    Training Attempts
                    </div>
                <div className={classes.reportForm}>
                    <div className={classNames(classes.selectors, classes.inputRoot)}>
                        <FormControl className={classes.formControl}>
                            <InputLabel >Users</InputLabel>
                            <Select
                                multiple
                                value={selectedPersons}
                                onChange={handlePeopleChange}
                                displayEmpty
                                input={<Input />}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                }}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {
                                            (selected as string[]).length > 0 ?
                                                (selected as string[]).map(u => (
                                                    <Chip key={`person-chip-${u}`} label={`${peopleWithTraining?.find(p => p.userName === u)?.firstName} ${peopleWithTraining?.find(p => p.userName === u)?.surname}`} className={classes.chip} />
                                                ))
                                                :
                                                <Chip key={`person-chip-all`} label="All" className={classes.chip} />
                                        }
                                    </div>
                                )}
                            >
                                {
                                    peopleWithTraining && peopleWithTraining.length > 0 ?
                                        peopleWithTraining.map(p => (
                                            <MenuItem key={p.userName} value={p.userName}>
                                                <Checkbox checked={selectedPersons.indexOf(p.userName) > -1} />
                                                <ListItemText primary={`${p.firstName} ${p.surname}`} />
                                            </MenuItem>
                                        ))
                                        :
                                        <MenuItem key={0} value={0}>
                                            No users available
                                        </MenuItem>
                                }

                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel >Modules</InputLabel>
                            <Select
                                multiple
                                value={selectedModules}
                                onChange={handleModuleChange}
                                displayEmpty
                                input={<Input />}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                }}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {
                                            (selected as number[]).length > 0 ?
                                                (selected as number[]).map(i => (
                                                    <Chip key={`module-chip-${i}`} label={`${modules?.find(m => m.id === i)?.name}`} className={classes.chip} />
                                                ))
                                                :
                                                <Chip key = {`module-chip-all${!!selected}`} label="All" className={classes.chip} />
                                        }
                                    </div>
                                )}
                            >
                                {
                                    modules && modules.length > 0 ?
                                        modules.map(m => (
                                            <MenuItem key={`module-${m.id}`} value={m.id}>
                                                <Checkbox checked={selectedModules.indexOf(m.id ? m.id : -1) > -1} />
                                                <ListItemText primary={`${m.name}`} />
                                            </MenuItem>
                                        ))
                                        :
                                        <MenuItem key={0} value={0}>
                                            No modules available
                                        </MenuItem>
                                }

                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.formBottom}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="start-date-picker-inline"
                                label="Range Start"
                                autoOk={true}
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="end-date-picker-inline"
                                label="Range End"
                                autoOk={true}
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={failed}
                                    onChange={handleFailedChange}
                                    name="failed"
                                    color="secondary"
                                />
                            }
                            label="Failed Only"
                        />
                        <span>
                            <Button variant="contained" color="primary" size="large" onClick={handleRunClick}>
                                See Results
                            </Button>
                        </span>
                        <span>
                            <Button variant="contained" color="primary" size="large" href={exportUrl}>
                                Export Results
                            </Button>
                        </span>
                    </div>
                </div>
                {
                    loadCompletedState?.state === RequestState.Success ?
                        <>
                            <div className={classes.summaryHeader}>
                                Results
                            </div>
                            {
                                completedModules && completedModules.length > 0 ?
                                    <TableContainer component={Paper}>
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell className={classNames(classes.headerWeight, classes.headerSize)} align="left">Staff Member</TableCell>
                                                    <TableCell className={classNames(classes.headerWeight, classes.headerSize)} align="center">Module</TableCell>
                                                    <TableCell className={classNames(classes.headerWeight, classes.headerSize)} align="center">Date</TableCell>
                                                    <TableCell className={classNames(classes.headerWeight, classes.headerSize)} align="center">Pass Mark</TableCell>
                                                    <TableCell className={classNames(classes.headerWeight, classes.headerSize)} align="center">Score</TableCell>
                                                    <TableCell className={classNames(classes.headerWeight, classes.headerSize)} align="center">Passed</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    (rowsPerPage > 0 ?
                                                        completedModules.sort().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : completedModules.sort()).map((module, index) => {
                                                            let prevUserName = index > 0 ? completedModules.sort().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)[index - 1].userName : "";
                                                            return <Row key={`row-${index}`} attempt={module} person={getUser(module.userName)} prevUserName={prevUserName} handleClick={handleResponseClick} />
                                                        })
                                                }
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow className={classes.footer}>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                        colSpan={7}
                                                        count={completedModules.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true,
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>

                                        </Table>
                                    </TableContainer>
                                    :
                                    <div>
                                        No completed attempts matching filter criteria
                                    </div>
                            }
                        </>
                       : <></>
                }
                <Dialog
                    open={!!selectedResponse && !!correctResponse}
                    onClose={handleClose}
                >
                    {
                        selectedResponse?.id !== correctResponse?.id  &&  <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span style={{ fontWeight: "bold" }}>Selected Response</span> <br /><br />
                                    {selectedResponse?.text}
                                </DialogContentText>
                            </DialogContent>
                            
                    }

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <span style={{ fontWeight: "bold" }}>Correct Response</span><br /><br />
                            {correctResponse?.text}
                        </DialogContentText>
                    </DialogContent>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </Dialog>
            </div>
        )
    }

    const showLoading = useDeferredValue(loadCompletedState?.state === RequestState.Pending);

    return (
        <div className={globalClass.root}>
            <div className={globalClass.body}>
                {
                    showLoading && (
                        <LinearProgress color="secondary" style={{ width: '100%' }} />
                        )
                }
                {
                    renderCompleted()
                }
            </div>
        </div>
    );

}




export default UserModules;
