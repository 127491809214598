import React, { Suspense } from 'react';
import './App.css';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Switch, useLocation, Route } from 'react-router-dom';
import * as StaffTrainingDtos from './dtos/StaffTrainingDtos';
import routes from './routes/routes';
import { createRoutes, loading } from './routes';
import { useSelector } from 'react-redux';
import { IPersonState } from './store/reducers/person';
import ConditionalRoute from './components/ConditionalRoute';
import ConditionalRedirect from './components/ConditionalRedirect';
import AdministrationWrapper from './pages/Administration/Wrapper';
import StaffWrapper from './pages/Staff/Wrapper';
import { IRequestState } from './types/IRequestState';
import { RequestState } from './types/RequestState';


interface IAppProps {
}

const useStyles = makeStyles<Theme>((theme) => ({
    '@global': {
        body: {
            margin: theme.spacing(0)
        }
    }
}))

const App: React.FunctionComponent<IAppProps> = () => {

    useStyles();

    const authenticatedPerson = useSelector<IPersonState, StaffTrainingDtos.Person | undefined>(state => state.personState.authenticatedPerson);
    const authPersonState = useSelector<IPersonState, IRequestState>(state => state.personState.loadAuthenticatedState);

    return (
        <div>
            <Suspense fallback={loading}>
                <Switch>
                    {
                        !!authenticatedPerson && (<ConditionalRoute enabled={true} path="/staff" component={StaffWrapper} /> )
                    }
                    {
                        authenticatedPerson?.isAdmin && (<ConditionalRoute enabled={true} path="/administration" component={AdministrationWrapper} />)
                    }
                    {
                        authenticatedPerson?.isAdmin && (<ConditionalRedirect enabled={true} path="/" to="/administration" />)
                    }
                    {
                        !!authenticatedPerson && (< ConditionalRedirect enabled={true} path="/" to="/staff" />)
                    }
                    {
                        !authenticatedPerson && authPersonState.state !== RequestState.None && authPersonState.state !== RequestState.Pending && (<ConditionalRedirect enabled={true} path="/" to="/error/401" />)
                    }
                </Switch>
            </Suspense>
        </div>
    );

}

export default App;
