

import { makeStyles, Theme } from '@material-ui/core/styles';

const globalStyles = makeStyles<Theme>((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[200],
        display: "flex",
        justifyContent: "center",
        fontFamily: 'Roboto, sans-serif',
        paddingBottom: theme.spacing(2),
        flex: '1 1 auto'
    },
    body: {
        backgroundColor: "#FFFFFF",
        width: "70%",
        boxShadow: "0 0 5px 1px #0000004a",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "min-content",
        [theme.breakpoints.down('sm')]: {
            width: "max-content"
        }
    },
    summaryHeader: {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        margin: "32px 0",
    },


}))


export default globalStyles;