import { makeStyles, Theme } from "@material-ui/core";
import React, { useMemo, useEffect } from "react";
import BannerHeading from "../../components/BannerHeading";
import { useSelector, useDispatch } from "react-redux";
import { IStoreState } from "../../store/createStore";
import { UserModuleAttempt } from "../../dtos/StaffTrainingDtos";
import classNames from 'classnames';
import { routerActions } from "connected-react-router";
import routes from "../../routes/routes";
import { modulePassed } from '../../utils/userModule';
import { Link } from '../../components/Link';
import { assessmentActions } from "../../store/reducers/assessment";
import CompletedModules from "../../components/CompletedModules";
import globalStyles from "../../styles/globalStyles";


interface IResultsProps {
}

interface IResultsStyleProps {
}

const useStyles = makeStyles<Theme, IResultsStyleProps>((theme) => ({
    
    trainingInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginBottom: theme.spacing(6)
    },
    resultsTable: {
        borderCollapse: "collapse",
        width: "100%",
        "& tbody": {
            display: "block",
            paddingBottom: theme.spacing(2)
        }
    },
    resultsTableRow: {
        display: "flex",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        justifyContent: "space-evenly",
        "& td": {
            minWidth: "30%",
            textAlign: "center",
            fontSize: "14pt"
        },
        color: theme.palette.grey[700]
    },
    greyRow: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
    },
    failedRow: {
        backgroundColor: theme.palette.error.light,
        borderColor: theme.palette.error.dark,
        color: theme.palette.error.dark
    },
    failedDark: {
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.dark,
        color: theme.palette.error.dark
    },
    resultsTableHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: "flex",
        justifyContent: "space-evenly",
        "& td": {
            minWidth: "30%",
            textAlign: "center",
            fontSize: "14pt"
        }
    },
    summaryHeader: {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        margin: "32px 0"
    },
    module: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "8px 32px 8px 32px",
        border: "solid 1px",
        "& span": {
            fontSize: "20pt",
            fontWeight: "normal"
        }
    },
    moduleHeader: {
        width: "45%",
        backgroundColor: theme.palette.common.white,
        border: "none",
        color: theme.palette.grey[600],
    },
    passed: {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.grey[600],
        color: theme.palette.grey[600],
    },
    failed: {
        backgroundColor: theme.palette.error.light,
        borderColor: theme.palette.error.dark,
        color: theme.palette.error.dark
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        color: "white",
        textDecoration: "none",
        fontSize: "14pt",
        width: "max-content",
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            transitionProperty: 'background-color',
            transitionDuration: '100ms',
            transitionTimingFunction: 'easi-in-out',
            WebkitTransitionProperty: 'background-color',
            WebkitTransitionDuration: '100ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        transitionProperty: 'background-color',
        transitionDuration: '100ms',
        transitionTimingFunction: 'easi-in-out',
        WebkitTransitionProperty: 'background-color',
        WebkitTransitionDuration: '100ms',
        WebkitTransitionTimingFunction: 'ease-in-out',
    },
    LeftColumn: {
        minWidth: "190px"
    }
}))

const Results: React.FunctionComponent<IResultsProps> = () => {

    const classes = useStyles({});
    const globalClasses = globalStyles({});

    const dispatch = useDispatch();

    const assessmentState = useSelector((state: IStoreState) => state.assessmentState);

    const assessedModules = useMemo(() => {
        if (assessmentState && assessmentState.assessableModules) {
            return assessmentState.assessableModules;
        }
    }, [assessmentState])

    useEffect(() => {
        if (!assessmentState.assessableModules || assessmentState.assessableModules.length === 0) {
            if (routerActions.push) {
                let toRoute = routes.staff.childRoutes?.home.path ? routes.staff.childRoutes?.home.path : "/";
                dispatch(routerActions.push(toRoute))
            }
        }

        return (() => {
            if (assessmentActions.clear) {
                dispatch(assessmentActions.clear())
            }
        })
    })

    const passed = (userModule: UserModuleAttempt): boolean => {
        return modulePassed(userModule);
    }

    return (
        <div className={globalClasses.root}>
            <div className={globalClasses.body}>
                <div className={classes.trainingInfo}>
                        {
                            assessedModules && assessedModules.length > 0 ?
                                <CompletedModules
                                    completedModules={assessedModules}
                                />
                                : null
                        }

                    <Link
                        to={routes.staff.childRoutes?.home.path}
                        className={classes.button}
                        disable={false}
                    >
                        Go home
                    </Link>
                </div>
            </div>
        </div>

        )

}

export default Results;
