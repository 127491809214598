
import moduleReducer, { moduleEpics } from './module'
import userModuleReducer, { userModuleEpics } from './userModule'
import { combineEpics } from 'redux-observable';
import personReducer, { personEpics } from './person';
import assessmentReducer, { assessmentEpics } from './assessment';

export const combinedEpics = combineEpics<any>(
    moduleEpics,
    userModuleEpics,
    personEpics,
    assessmentEpics,
);

export const combinedReducers = {
    moduleState: moduleReducer,
    userModuleState: userModuleReducer,
    personState: personReducer,
    assessmentState: assessmentReducer,
};