import React, { useState, useMemo, useEffect } from 'react';

import { makeStyles, Theme } from '@material-ui/core';

import classNames from 'classnames';

interface ITimerProps {
    initialSeconds: number;
    run: boolean;
    onTimerFinish?: () => void;
    onTimerStart?: (startTime: number) => void;
    onPeriodic?: () => void;
    className?: string;
}

interface ITimerStyleProps {
}

const useStyles = makeStyles<Theme, ITimerStyleProps>((theme: Theme) => ({
    root: {

    }
}))


export const Timer: React.FunctionComponent<ITimerProps> = ({ initialSeconds, run, onTimerFinish, onTimerStart, className }) => {

    const classes = useStyles({});

    const [totalSeconds, setTotalSeconds] = useState(initialSeconds);
    const [startTime, setStartTime] = useState<number>(0);

    const [started, setStarted] = useState<boolean>(false);

    let stopTimer: boolean = false;

    const minutes = useMemo(() => {
        return Math.floor(totalSeconds/60)
    }, [totalSeconds])

    const seconds = useMemo(() => {
        return totalSeconds - minutes * 60;
    }, [totalSeconds, minutes])

    useEffect(() => {
        return () => {

        }
    });

    useEffect(() => {
        if (run) {
            setStartTime(Date.now());
        }
    }, [run])

    useEffect(() => {
        if (onTimerStart && startTime > 0 && !started) {
            setStarted(true);
            onTimerStart(startTime);
        }
    }, [startTime, onTimerStart]);



    useEffect(() => {
        if (run) {
            let timeInterval = setInterval(() => {
                if (totalSeconds <= 0) {
                    clearInterval(timeInterval);
                    if (onTimerFinish) {
                        onTimerFinish();
                    }
                }
                let delta = Math.floor((Date.now() - startTime) / 1000);
                //console.log("startTime: " +(startTime / 1000));
                //console.log("DateTimeNow: " + Date.now() / 1000);
                //console.log("Delta: " + delta);
                //console.log("InitialSeconds: " + initialSeconds);
                setTotalSeconds(initialSeconds - delta);
            }, 1000)
            return () => {
                clearInterval(timeInterval);
            }
        }
    });

    return (
        <div className={classNames(classes.root, className)}>
            {minutes.toString().padStart(2, '0')} : {seconds.toString().padStart(2, '0')} 
        </div>
    );
}

export default Timer;