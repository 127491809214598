import React, { useMemo, useEffect } from 'react';
import { makeStyles, Theme, TableFooter, LinearProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { IStoreState } from '../../store/createStore';
import { Person } from '../../dtos/StaffTrainingDtos';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { isOverdue, attemptNumber } from '../../utils/userModule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { personActions } from '../../store/reducers/person';
import classNames from 'classnames';
import * as StaffTrainingDtos from '../../dtos/StaffTrainingDtos';
import { useLocation } from 'react-router';
import { RequestState } from '../../types/RequestState';
import globalStyles from '../../styles/globalStyles';
import { useDeferredValue } from '../../utils/defferedValue';


interface IHomeProps {
}

interface IHomeStyleProps {
}

const useStyles = makeStyles<Theme, IHomeStyleProps>((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[200],
        display: "flex",
        justifyContent: "center",
        fontFamily: 'Roboto, sans-serif',
        paddingBottom: theme.spacing(2),
        flex: '1 1 auto'
    },
    body: {
        backgroundColor: "#FFFFFF",
        width: "70%",
        boxShadow: "0 0 5px 1px #0000004a",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "min-content",
        [theme.breakpoints.down('sm')]: {
            width: "max-content"
        }
    },
    overdueContaininer: {
        width: "70%",
        textAlign: "center",
        "& .MuiTableContainer-root": {
            boxShadow: "0 0 5px 1px #0000004a"
        },
        marginBottom: theme.spacing(8),
        marginTop: theme.spacing(4)
    },
    row: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    summaryHeader: {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        margin: "32px 0",
    },
    results: {

    },
    footer: {
        height: theme.spacing(3)
    },
    headerWeight: {
        fontWeight: "bold"
    },
    headerSize: {
        fontSize: "12pt"
    },
    warning: {
        color: theme.palette.error.dark
    },
}))



const Row = (props: { person: Person }) => {
    const { person } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles({});

    if (!person.outstandingModules || person.outstandingModules.length === 0) {
        return null;
    }

    const orderedModules = person.outstandingModules.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());

    const mostRecentFinishTime = (completedModules: StaffTrainingDtos.UserModuleAttempt[], moduleId: number) => {
        var result: string = "";
        if (completedModules && completedModules.length > 0) {
            let sortedModules = completedModules.filter(m => m.moduleId === moduleId).sort((a, b) => { return new Date(b.finishTime ? b.finishTime.toString() : "").valueOf() - new Date(a.finishTime ? a.finishTime.toString() : "").valueOf() });

            let mostRecentFinishTime: string = sortedModules && sortedModules.length > 0 && sortedModules[0].finishTime ? sortedModules[0].finishTime : "";

            result = mostRecentFinishTime;
        }
        return result;
    }

    const overdue = getOverdue(orderedModules);

    return (
        <>
            <TableRow className={classes.row}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.headerSize} align="left" component="th" scope="row">
                    {`${person.firstName} ${person.surname}`}
                </TableCell>
                <TableCell className={classes.headerSize} align="center">{new Date(orderedModules[0]?.dueDate).toLocaleDateString('en-GB')}</TableCell>
                <TableCell className={classes.headerSize} align="center">{overdue && overdue.length> 0 ? <FontAwesomeIcon className={classes.warning} icon={faCheck} fixedWidth /> : ""}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} marginBottom={3}>
                            <Typography variant="h6" gutterBottom component="div">
                                Modules
                            </Typography>
                            <Table size="small" aria-label="courses">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classNames(classes.headerWeight, classes.headerSize)}>Name</TableCell>
                                        <TableCell className={classNames(classes.headerWeight, classes.headerSize)}>Attempt Number</TableCell>
                                        <TableCell className={classNames(classes.headerWeight, classes.headerSize)}>Last Attempt</TableCell>
                                        <TableCell className={classNames(classes.headerWeight, classes.headerSize)}>Due Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {person.outstandingModules.map((module, index) => (
                                        <TableRow key={`userModule-${module.id}`} >
                                            <TableCell component="td" scope="row" className={overdue?.includes(index) ? classNames(classes.warning, classes.headerWeight) : ""}>
                                                { module.module.name}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {attemptNumber(person.completedModules, module)}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {
                                                    person.completedModules && person.completedModules.length > 0 && person.completedModules.some(m => m.moduleId == module.moduleId) ?
                                                        new Date(mostRecentFinishTime(person.completedModules, module.moduleId)).toLocaleDateString('en-GB')
                                                        : "N/A"
                                                }
                                            </TableCell>
                                            <TableCell component="td" scope="row" className={overdue?.includes(index) ? classNames(classes.warning, classes.headerWeight) : ""}>
                                                {new Date(module.dueDate).toLocaleDateString('en-GB')}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
        )

}

const Home: React.FunctionComponent<IHomeProps> = () => {

    const classes = useStyles({});
    const globalClass = globalStyles({});

    const people = useSelector((state: IStoreState) => state.personState.peopleWithTraining);
    const loadWithTrainingState = useSelector((state: IStoreState) => state.personState.loadWithTrainingState);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(personActions.loadWithTraining(true, true, false, true))

        return () => {
            dispatch(personActions.clear())
        }
    }, [])

    const renderOverdue = (people: Person[]) => {
        return (
                <div className={classes.overdueContaininer}>
                    <div className={classes.summaryHeader}>
                    Upcoming / Overdue Training
                    </div>
                {
                    people && people.length > 0 && loadWithTrainingState.state === RequestState.Success ?
                            <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell className={classNames(classes.headerWeight, classes.headerSize)} align="left">Staff Member</TableCell>
                                            <TableCell className={classNames(classes.headerWeight, classes.headerSize)} align="center">Due Date</TableCell>
                                            <TableCell className={classNames(classes.headerWeight, classes.headerSize)} align="center">Overdue</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {people.map((person, index) => (
                                        <Row key={`row-${index}`} person={person} />
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow className={classes.footer}>

                                        </TableRow>
                                    </TableFooter>

                                </Table>
                            </TableContainer>
                        : <></>
                    }
                </div>
                
            )
    }

    const showLoading = useDeferredValue(loadWithTrainingState?.state === RequestState.Pending);

    return (
        <div className={globalClass.root}>
            <div className={globalClass.body}>
                {
                     showLoading && (
                        <LinearProgress color="secondary" style={{ width: '100%' }} />
                    )
                }
                {
                    people ?
                        renderOverdue(people)
                        : null
                }
            </div>
        </div>
        );
}

const getOverdue = ((orderedModules: StaffTrainingDtos.UserModuleAttempt[]) => {
    let overDueList: number[] = [];
    if (orderedModules && orderedModules.length > 0) {
        orderedModules.forEach((m, index) => {
            if (isOverdue(m.dueDate)) {
                overDueList.push(index);
            }
        });
    }
    return overDueList;
});

export default Home;