import React, { useEffect, useMemo, useState, createContext, useRef} from 'react';
import { makeStyles, Theme, Typography, Container, LinearProgress} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { IStoreState } from '../../store/createStore';
import { assessmentActions } from '../../store/reducers/assessment';
import { BannerHeading } from '../../components/BannerHeading';
//import { AssessmentStatus } from '../../enums/AssessmentStatus';
import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { timeLimit, allQuestionsAnswered, initialTimeLimit } from '../../utils/userModule';
import Module from '../../components/Module';
import Timer from '../../components/Timer';
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import classNames from 'classnames';
import { RequestState } from '../../types/RequestState';
import { routerActions } from 'connected-react-router';
import { useLocation, useParams, Redirect, useHistory } from 'react-router';
import routes from '../../routes/routes';
import { ServerEventsClient, ServerEventConnect, ServerEventLeave } from '@servicestack/client';
import { AssessmentStatus } from "../../dtos/StaffTrainingDtos";
import globalStyles from '../../styles/globalStyles';
import { useDeferredValue } from '../../utils/defferedValue';

interface IAssessmentProps {
}

interface IAssessmentStyleProps {
    startButtonHidden?: boolean,
    disableSubmit?: boolean,
    scrolled?: boolean,
    modulesActive?: boolean,
    status?: AssessmentStatus,
}

const useStyles = makeStyles<Theme, IAssessmentStyleProps>((theme) => ({
    root: {
        //backgroundColor: theme.palette.grey[200],
        height: "100%",
        display: "flex",
        justifyContent: "center",
        fontFamily: 'Roboto, sans-serif',
        paddingBottom: theme.spacing(2)
    },
    body: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0 0 5px 1px #0000004a",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    assessmentInfo: {
        width: "60%",
        marginTop: theme.spacing(8),
    },
    summaryHeader: {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        marginBottom: theme.spacing(4)
    },
    moduleList: {
        listStyle: "disc inside none",
        color: theme.palette.secondary.main,
        fontSize: "24px",
        "& span": {
            fontSize: "16px"
        }
    },
    instructions: {
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        padding: "16px 32px",
        borderRadius: theme.spacing(1),
        color: "white",
        textDecoration: "none",
        width: "max-content",
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            transitionProperty: 'background-color',
            transitionDuration: '100ms',
            transitionTimingFunction: 'ease-in-out',
            WebkitTransitionProperty: 'background-color',
            WebkitTransitionDuration: '100ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        transitionProperty: 'background-color',
        transitionDuration: '100ms',
        transitionTimingFunction: 'ease-in-out',
        WebkitTransitionProperty: 'background-color',
        WebkitTransitionDuration: '100ms',
        WebkitTransitionTimingFunction: 'ease-in-out',
    },
    submitButton: {
        backgroundColor: (props: IAssessmentStyleProps) => props.disableSubmit ? theme.palette.grey[600] : theme.palette.primary.main,
        padding: "16px 32px",
        borderRadius: theme.spacing(1),
        border: `solid 2px`,
        borderColor: (props: IAssessmentStyleProps) => props.disableSubmit ? theme.palette.grey[600] : theme.palette.primary.main,
        color: (props: IAssessmentStyleProps) => props.disableSubmit ? theme.palette.grey[100] : theme.palette.common.white,
        textDecoration: "none",
        fontSize: "14pt",
        width: "max-content",
        '&:hover': {
            backgroundColor: (props: IAssessmentStyleProps) => props.disableSubmit ? theme.palette.grey[600] : theme.palette.common.white,
            color: (props: IAssessmentStyleProps) => props.disableSubmit ? "" : theme.palette.primary.dark,
            border: (props: IAssessmentStyleProps) => props.disableSubmit ? "" : ` solid 2px ${theme.palette.primary.main}`,
            transitionProperty: 'background-color color border',
            transitionDuration: '100ms',
            transitionTimingFunction: 'ease-in-out',
            WebkitTransitionProperty: 'background-color',
            WebkitTransitionDuration: '100ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        transitionProperty: 'background-color color border',
        transitionDuration: '100ms',
        transitionTimingFunction: 'ease-in-out',
        WebkitTransitionProperty: 'background-color',
        WebkitTransitionDuration: '100ms',
        WebkitTransitionTimingFunction: 'ease-in-out',
    },
    buttonContainer: {
        margin: theme.spacing(4),
        visibility: (props: IAssessmentStyleProps) => props.startButtonHidden ? "hidden" : "visible",
    },
    submitButtonContainer: {
        margin: (props: IAssessmentStyleProps) => props.status !== AssessmentStatus.InProgress && props.status ? "0px" : `${theme.spacing(2)}px ${theme.spacing(0)}px`,
        display: "flex",
        justifyContent: "center",

        visibility: (props: IAssessmentStyleProps) => props.status !== AssessmentStatus.InProgress ? "hidden" : "visible",
    },
    stepperButton: {
        fontSize: "1rem",
        padding: theme.spacing(1),
        marginLeft: theme.spacing(2),
        backgroundColor: (props: IAssessmentStyleProps) => props.status !== AssessmentStatus.InProgress ? theme.palette.grey[600] : theme.palette.primary.main,
        '&:hover': {
            backgroundColor: (props: IAssessmentStyleProps) => props.status !== AssessmentStatus.InProgress ? theme.palette.grey[600] : theme.palette.secondary.main,
            transitionProperty: 'background-color color border',
            transitionDuration: '100ms',
            transitionTimingFunction: 'ease-in-out',
            WebkitTransitionProperty: 'background-color',
            WebkitTransitionDuration: '100ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
    },
    timeAllowed: {
        color: theme.palette.secondary.main,
        fontSize: "20px",
        paddingLeft: theme.spacing(2)
    },
    timerTwoLabelContainer: {
        width: '100%',
        padding: theme.spacing(2),
        fontSize: "40px",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
    },
    timerTwoLabel: {
    },
    timerTwo: {
        position: 'sticky',
        fontSize: "40px",
        top: (props: IAssessmentStyleProps) => props.scrolled ? theme.spacing(5) : theme.spacing(1),
        left: 0,
        zIndex: 2,
    },
    timerTwoOutput: {
        position: 'absolute',
        whiteSpace: 'nowrap',
        top: "12px",
        left: (props: IAssessmentStyleProps) => props.scrolled ? '100%' : '330px',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        transitionProperty: 'left, top',
        transitionDuration: '250ms',
        transitionTimingFunction: 'ease-in-out',
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
    },
    timer: {
        display: "flex",
        flexDirection: "row",
        marginTop: (props: IAssessmentStyleProps) => props.scrolled ? theme.spacing(0) : theme.spacing(4),
        marginBottom: (props: IAssessmentStyleProps) => props.scrolled ? theme.spacing(0) : theme.spacing(4),
        marginLeft: theme.spacing(4),
        color: "#FFFFFF",
        fontSize: "40px",
        width: "max-content",
        justifyContent: "center",
        zIndex: 2,
        backgroundColor: theme.palette.secondary.main,
        position: "sticky",
        padding: (props: IAssessmentStyleProps) => props.scrolled ? theme.spacing(2) : "0",
        borderRadius: theme.spacing(2),
        left: (props: IAssessmentStyleProps) => props.scrolled ? "calc(100% - 220px)" : "0",
        top: (props: IAssessmentStyleProps) => props.scrolled ? theme.spacing(5) : "0",
        transitionProperty: 'left, top',
        transitionDuration: '250ms',
        transitionTimingFunction: 'ease-in-out',
        WebkitTransitionProperty: 'left, top',
        WebkitTransitionDuration: '250ms',
        WebkitTransitionTimingFunction: 'ease-in-out',
    },
    moduleWrapper: {
        width: "100%",
        backgroundColor: theme.palette.secondary.main
    },
    timerLabel: {
        marginRight: "32px",
        display: (props: IAssessmentStyleProps) => props.scrolled ? "none" : ""
    },
    moduleLabel: {
        "& .MuiTypography-body2": {
            fontSize: "24px",
            fontWeight: "bold",
            color: theme.palette.grey[600],
            justifyContent: "space-between",
            '&:hover': {
                color: (props: IAssessmentStyleProps) => props.modulesActive ? theme.palette.secondary.main : theme.palette.grey[600],
            }
        },
        '&:hover': {
            cursor: (props: IAssessmentStyleProps) => props.modulesActive ? "pointer" : "default",
            "& .MuiStepLabel-iconContainer": {
                "& .MuiSvgIcon-root": {
                    color: (props: IAssessmentStyleProps) => props.modulesActive ? theme.palette.secondary.main : theme.palette.grey[600],
                }
            },
        }
    },
    selectedModuleLabel: {
        "& .MuiTypography-body2": {
            color: (props: IAssessmentStyleProps) => props.modulesActive ? theme.palette.primary.main : theme.palette.grey[600],
        },
        "& .MuiTypography-root": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }
    },
    closedModuleLabel: {
        "& .MuiTypography-root": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }
    },
    submitMessage: {
        color: theme.palette.common.white,
        display: (props: IAssessmentStyleProps) => props.disableSubmit ? "flex" : "none",
        alignItems: "center",
        marginLeft: theme.spacing(2),
        fontSize: "14pt"
    }
}))

interface IAssessmentParams {
    token?: string;
}

const AssessmentOuter = () => {

    const { token } = useParams<IAssessmentParams>();
    const history = useHistory();

    const [storedToken] = useState(() => token === 'resume' || token === 'begin' ? token : undefined);

    useEffect(() => {
        if (storedToken) {
            history.replace('/staff/assessment');
        }
    }, [])

    if (!storedToken) {
        return <Redirect path="/" to="/staff/home" />
    }

    return <Assessment />;
};

const Assessment: React.FunctionComponent<IAssessmentProps> = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    const modulesRef = useRef(document.createElement("div"));

    const [status, setStatus] = useState(AssessmentStatus.Preview);
    const [activeStep, setActiveStep] = useState<number>(-1);
    const [scrolled, setScrolled] = useState(false);
    const [allResponses, setAllResponses] = useState(false);

    const [clientState, setClientState] = useState<ServerEventsClient | undefined>();

    const handleStep = (step: number) => () => {
        if (status !== AssessmentStatus.Preview) {
            setActiveStep(step === activeStep ? -1 : step);
            scrollToModules();
        }
    };

    var client: ServerEventsClient;

    const assessmentState = useSelector((state: IStoreState) => state.assessmentState);
    const authenticatedUser = useSelector((state: IStoreState) => state.personState.authenticatedPerson);

    const assessableModules = useMemo(() => {
        return assessmentState.assessableModules
    }, [assessmentState])

    const startListening = () => {
        if (authenticatedUser) {
            console.log(`Connecting to ${window.location.origin} for ${authenticatedUser.userName} training`);
            client = new ServerEventsClient(window.location.origin, [authenticatedUser.userName], {
                handlers: {
                    onConnect: (e: ServerEventConnect) => {
                        console.log(`Connected: ${e.displayName}`);
                    },
                    onUnsubscribe: (e: ServerEventLeave) => {
                        console.log(`Disconnected from : ${e.displayName}`)
                    }
                },
                onException: (e: Error) => {
                    console.log(`Error: ${e}`)
                }
            }).start();
        }
    }

    const stopListenting = () => {
        console.log(`Closing connection to ${window.location.origin} training`);
        if (client != undefined) {
            client.stop();
        }
        console.log(`Connection closed`);
    }

    useEffect(() => {
        return (() => {
            stopListenting();
        })
    }, [])

    useEffect(() => {
        if (assessmentActions.loadAssessable && assessmentState.loadAssessableModulesState.state === RequestState.None) {
            dispatch(assessmentActions.loadAssessable())
        }
    }, [location.pathname])
    

    useEffect(() => {
        if (assessableModules) {
            if (assessmentState.updateQuestionResponseState.state === RequestState.Success || status === AssessmentStatus.InProgress) {
                setAllResponses(allQuestionsAnswered(assessableModules));
            }
        }
    }, [assessmentState.updateQuestionResponseState.state, assessableModules, status])

    

    const classes = useStyles({
        startButtonHidden: status === AssessmentStatus.InProgress,
        disableSubmit: !allResponses && status === AssessmentStatus.InProgress,
        scrolled: scrolled,
        modulesActive: status !== AssessmentStatus.Preview,
        status: status,
    })

    const globalClasses = globalStyles({});
    
    const intro = useMemo(() => {
        let introduction = assessableModules?.length === 1 ?
            "The following module is to be attempted:" :
            "The following modules are to be attempted:";

        return introduction;
    }, [assessableModules]);

    
    const startAssessment = () => {
        setStatus(AssessmentStatus.InProgress);
        setActiveStep(0);
        startListening();
    }

    const endAssessment = (suppliedStatus: AssessmentStatus) => {
        setStatus(suppliedStatus);
        setActiveStep(-1);
        let attempts = assessmentState.assessableModules;
        if (assessmentActions.endAssessment && attempts) {

            let finishTimeStamp = new Date();

            if (suppliedStatus === AssessmentStatus.Complete || suppliedStatus === AssessmentStatus.Exited) {
                attempts?.forEach((a) => {
                    a.finishTime = finishTimeStamp.toISOString()
                })
            }

            dispatch(assessmentActions.endAssessment(assessableModules, suppliedStatus))
        }
    }

    const scrollToModules = () => {
        if (modulesRef && modulesRef.current) {
            modulesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const hasModules = useMemo(() => {
        return (assessableModules && assessableModules.length > 0);
    }, [assessableModules]);

    const listenToScroll = () => {
        let moduleWrapper = document.getElementById("moduleWrapper");

        let moduleOffset = moduleWrapper ? moduleWrapper.getBoundingClientRect().top : 0;

        if (moduleOffset <= 0) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);

        return function cleanup() {
            window.removeEventListener('scroll', listenToScroll);
        }
    })

    useEffect(() => {
        if (assessmentState.loadAssessableModulesState.state === RequestState.Success && (!assessableModules || (assessableModules && assessableModules.length === 0)) && status === AssessmentStatus.Preview) {
            if (routerActions.push) {
                let toRoute = routes.staff.childRoutes?.home.path ? routes.staff.childRoutes?.home.path : "/";
                dispatch(routerActions.push(toRoute))
            }
        }
    }, [assessmentState.loadAssessableModulesState, assessableModules, dispatch, status])

    const allowedTime = useMemo(() => {
        if (assessableModules && assessableModules.length > 0) {
            return timeLimit(assessableModules);
        }
    }, [assessableModules?.length])

    const initialAllowedTime = useMemo(() => {
        if (assessableModules && assessableModules.length > 0) {
            return initialTimeLimit(assessableModules);
        }
    }, [assessableModules])

    useEffect(() => {
        if (assessableModules && assessableModules.length > 0) {
            if (allowedTime === 0 && status !== AssessmentStatus.Complete) {
                setStatus(AssessmentStatus.TimeLimitReached);
            }
            else {
                if (assessableModules[0].startTime && status === AssessmentStatus.Preview) {
                    if (allowedTime) {
                        setStatus(AssessmentStatus.InProgress);
                        timerStarted(allowedTime);
                        setActiveStep(0);
                        startListening();
                    }
                }
            }
        }
    }, [allowedTime, assessableModules, status])



    const timeLimitReached = () => {
        endAssessment(AssessmentStatus.TimeLimitReached);
    };

    const timerStarted = (startTime: number) => {
        if (assessmentActions.beginAssessment) {

            let attempts = assessmentState.assessableModules;

            if (attempts) {

                if (!attempts[0].startTime) {
                    let startTimeStamp = new Date(startTime);

                    attempts?.forEach((a) => {
                        a.startTime = startTimeStamp.toISOString()
                    })
                }

                dispatch(assessmentActions.beginAssessment(attempts))
            }
        }
    }

    const canViewResults = useMemo(() => {
        var result = false;
        if (assessableModules && assessableModules.length > 0 && assessableModules[0].finishTime) {
            result = true;
        }
        return result;
    }, [assessableModules, status])

    const renderModulesStepper = () => {
        return <div ref={modulesRef}>
            <Stepper orientation="vertical" nonLinear activeStep={activeStep}>
                {
                    status !== AssessmentStatus.Complete && status !== AssessmentStatus.TimeLimitReached ?
                    assessmentState.assessableModules?.map((userModule, index) => {
                        if (userModule.module && userModule.id) {
                            return (
                                <Step key={`step-${index}`}>
                                    <StepLabel className={classNames(classes.moduleLabel, activeStep === index ? classes.selectedModuleLabel : classes.closedModuleLabel)} onClick={handleStep(index)}>
                                        {userModule.module?.name}
                                        <div className={classes.stepperButtonContaininer}>
                                            <Button className={classNames(classes.button, classes.stepperButton)} handleClick={handleStep(index)} disable={status !== AssessmentStatus.InProgress}>
                                                {
                                                    activeStep === index ?
                                                        "Hide"
                                                        :
                                                        "Show"
                                                }
                                            </Button>
                                        </div>
                                    </StepLabel>
                                    <StepContent>
                                        <Module key={`module-${index}`} moduleAttempt={userModule} />
                                    </StepContent>
                                </Step>
                            );
                        }
                        
                    })
                        : null
                }
            </Stepper>
        </div>
    }

    const instructions = useMemo(() => {

        let instructions;

        switch (status) {
            case null:
            case AssessmentStatus.Preview:
            case AssessmentStatus.InProgress: {
                if (hasModules) {
                    instructions = <>
                        <p>Please read instructions carefully.</p>
                        {intro}
                        < ul className={classes.moduleList} >
                            {
                                assessmentState.assessableModules?.map((module, index) => {
                                    return (
                                        <li key={`moduleItem-${index}`}>
                                            <span>{module.module?.name}</span>
                                        </li>
                                    );
                                })
                            }
                        </ul >
                        <div className={classes.instructions}>
                            <p>Once you click the "Begin" button, the first module will be displayed.</p>
                            <p>You can chose to expand or hide any module at any time.</p>
                            <p>You must answer all questions from all modules to submit this assessment.</p>
                            <p>After completion, you will be shown your mark for each module.</p>
                            <p>If you don't finish within the time limit your assessment will be submitted as is</p>
                            <p>Time allowed for this asssessment is: <span className={classes.timeAllowed}>{initialAllowedTime ? initialAllowedTime / 60 : 0} minutes</span></p>
                        </div>
                    </>
                } else {

                    instructions = <div className = { classes.instructions }>
                                        <p>There are no modules to be assessed.</p>
                    </div>

                   
                }

                break;
            }
            case AssessmentStatus.Complete: {
                instructions = <div className={classes.instructions}>
                    <p>Thank you for your submission</p>
                    <p>To view your results please click the link below</p>
                </div>

                break;
            }
            case AssessmentStatus.TimeLimitReached: {
                instructions = <div className={classes.instructions}>
                    <p>Your allotted time has expired</p>
                    <p>All of your responses have been recorded</p>
                    <p>To view your results please click the link below</p>
                </div>

                break;
            }
        }

        return instructions;

    }, [status, hasModules, assessmentState.assessableModules, classes.instructions, classes.moduleList, classes.timeAllowed, initialAllowedTime, intro])

    const showLoading = useDeferredValue(assessmentState?.loadAssessableModulesState?.state === RequestState.Pending);

    return (
        <div className={globalClasses.root}>
            <div className={globalClasses.body}>
                {
                    showLoading && (
                        <LinearProgress color="secondary" style={{ width: '100%' }} />
                    )
                }
                <div className={classes.assessmentInfo}>
                    <div className={classes.summaryHeader}>
                        {
                            status !== AssessmentStatus.Complete && status !== AssessmentStatus.TimeLimitReached ?
                                "Assessment Details"
                                :
                                assessmentState.endAssessmentState.state === RequestState.Success ?
                                    "Assessment finished"
                                    : null
                        }
                    </div>
                    {instructions}

                </div>
                <div className={classes.buttonContainer}>
                    {
                        status === AssessmentStatus.Preview || status === AssessmentStatus.InProgress ?
                            <Button className={classes.button} handleClick={startAssessment}>
                                Begin
                            </Button>
                            :
                            status === AssessmentStatus.Complete || status === AssessmentStatus.TimeLimitReached ?
                                <Link
                                    disable={!canViewResults}
                                    to={routes.staff.childRoutes?.results.path}
                                    className={classes.button}
                                >
                                        View Results
                                </Link>
                                : null

                    }
                </div>
                <div id="moduleWrapper" className={classes.moduleWrapper}>
                    {!!allowedTime && (status === AssessmentStatus.Preview || status === AssessmentStatus.InProgress) && (<div className={classes.timerTwo}>
                        <Typography
                            className={classes.timerTwoOutput}
                            variant="h1"
                            variantMapping={{ h1: 'div' }}
                        >
                            
                                <Timer
                                initialSeconds={Math.floor(allowedTime)}
                                run={status === AssessmentStatus.InProgress}
                                    onTimerFinish={timeLimitReached}
                                    onTimerStart={timerStarted}
                                />
                                
                        </Typography>
                    </div>
                    )}
                    {
                        status === AssessmentStatus.Preview || status === AssessmentStatus.InProgress ?
                        <div className={classes.timerTwoLabelContainer}>
                            <Typography
                                className={classes.timerTwoLabel}
                                variant="h1"
                                variantMapping={{ h1: 'div' }}
                            >
                                Time Remaining:
                            </Typography>
                        </div>
                            : null
                    }
                    {
                        hasModules ?
                            renderModulesStepper()
                            : null

                    }
                    <div className={classes.submitButtonContainer}>
                        <Button className={classes.submitButton} handleClick={() => endAssessment(AssessmentStatus.Complete)} disable={!allResponses}>
                            Submit
                        </Button>
                        <div className={classes.submitMessage}>
                            Must answer all questions before submitting
                        </div>
                    </div>
                </div>

            </div>
        </div>
        );
}

export default AssessmentOuter;


export const AssessmentContext = createContext(Assessment);