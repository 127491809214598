import { makeStyles, Theme, FormControl, InputLabel, Select, Input, MenuItem, Chip, useTheme, Checkbox, ListItemText, Button, Tooltip, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import React, { useMemo, useEffect, useState } from "react";
import BannerHeading from "../../components/BannerHeading";
import { useSelector, useDispatch } from "react-redux";
import { IStoreState } from "../../store/createStore";
import { UserModuleAttempt, Person, Module } from "../../dtos/StaffTrainingDtos";
import classNames from 'classnames';
import { routerActions } from "connected-react-router";
import routes from "../../routes/routes";
import { modulePassed } from '../../utils/userModule';
import { Link } from '../../components/Link';
import globalStyles from '../../styles/globalStyles';
import { personActions } from "../../store/reducers/person";
import { userModuleActions } from "../../store/reducers/userModule";
import { moduleActions } from "../../store/reducers/module";
import { RequestState } from "../../types/RequestState";

interface ITrainingAssignmentProps {
}

interface ITrainingAssignmentStyleProps {
    moreThan5People: boolean
}

const useStyles = makeStyles<Theme, ITrainingAssignmentStyleProps>((theme) => ({

    assignmentForm: {
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(8),
        width: "90%"
    },

    selectors: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        "& .MuiSelect-select.MuiSelect-select": {
            minWidth: "390px",
        },
        "& .MuiInputLabel-formControl": {
            position: "relative",
            marginBottom: "20px"
        },
        "& .MuiInputLabel-shrink": {
            transform: "none"
        },
        "& .MuiInputLabel-animated": {
            transition: "none"
        }
    },
    selection: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        "& .MuiSelect-select.MuiSelect-select": {
            minWidth: "390px",
        },
        "& .MuiInputLabel-formControl": {
            position: "relative",
            marginBottom: "20px"
        },
        "& .MuiInputLabel-shrink": {
            transform: "none"
        },
        "& .MuiInputLabel-animated": {
            transition: "none"
        },
        "& .MuiSelect-select": {
            "&:hover": {
                curser: "default",

                }
        },
    },
    inputRoot: {
        "& .MuiInputBase-root": {
            maxWidth: "420px",
            "& select": {
                "& option": {
                    paddingLeft: "10px"
                }
            }
        }
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    formBottom: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center"
    }
}))

const TrainingAssignment: React.FunctionComponent<ITrainingAssignmentProps> = () => {

    const globalClass = globalStyles({});

    //var divStyle = {minHeight: "calc(100% - 220px)"}

    const people = useSelector((state: IStoreState) => state.personState.people);
    const modules = useSelector((state: IStoreState) => state.moduleState.data);
    const assignmentStatus = useSelector((state: IStoreState) => state.userModuleState.assignUserTrainingState);
    const dispatch = useDispatch();

    const classes = useStyles({ moreThan5People: (people && people.length > 5) ? true : false });
    const theme = useTheme();
    const [selectedPersons, setSelectedPersons] = useState<string[]>([])
    const [selectedModules, setSelectedModules] = useState<number[]>([])
    const [selectedDate, setSelectedDate] = useState<Date | null>(
        () => {
            let date = new Date();
            date.setDate(date.getDate() + 28);
            return date;
        }
    )
    const [repeat, setRepeat] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    

    useEffect(() => {
            dispatch(personActions.load());
            dispatch(moduleActions.load());
    }, [])

    useEffect(() => {
        let result = false;
        if (assignmentStatus?.state == RequestState.Success || assignmentStatus?.state == RequestState.Failure) {
            result = true;
        }
        setShowModal(result);
    }, [assignmentStatus.state])

    const handleAssignClick = () => {
        if (userModuleActions.assignUserTraining && selectedPersons.length > 0 && selectedModules.length > 0) {
            dispatch(userModuleActions.assignUserTraining(selectedPersons, selectedModules, selectedDate?.toISOString(), repeat))
        }
    };

    const handlePeopleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedPersons(event.target.value as string[]);
    };

    const handleModuleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedModules(event.target.value as number[]);
    };

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const handleRepeatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRepeat(event.target.checked);
    };

    const handleCloseModal = () => {
        setSelectedPersons([]);
        setSelectedModules([]);
        setRepeat(false);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const submitDisabled = useMemo(() => {
        let disabled = false;
        if (selectedPersons?.length === 0 || selectedModules?.length === 0) {
            disabled = true;
        }
        return disabled;
    }, [selectedPersons, selectedModules]);


    //const ITEM_HEIGHT = 48;
    //const ITEM_PADDING_TOP = 8;
    //const MenuProps = {
    //    PaperProps: {
    //        style: {
    //            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //            width: 250,
    //        },
    //    },
    //}

    return (
        <div className={globalClass.root}>
            <div className={classNames(globalClass.body)}>
                <div className={globalClass.summaryHeader}>
                    Assign Training
                </div>
                <div className={classes.assignmentForm}>
                    <div className={classNames(classes.selectors, classes.inputRoot)}>
                        <FormControl className={classes.formControl}>
                            <InputLabel >Users</InputLabel>
                            <Select
                                multiple
                                value={selectedPersons}
                                onChange={handlePeopleChange}
                                input={<Input />}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                }}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {(selected as string[]).map(u => (
                                            <Chip key={`person-chip-${u}`} label={`${people?.find(p => p.userName === u)?.firstName} ${people?.find(p => p.userName === u)?.surname}`} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                            >
                                {
                                    people && people.length > 0 ?
                                        people.map(p => (
                                            <MenuItem key={p.userName} value={p.userName}>
                                                <Checkbox checked={selectedPersons.indexOf(p.userName) > -1} />
                                                <ListItemText primary={`${p.firstName} ${p.surname}`} />
                                            </MenuItem>
                                        ))
                                        :
                                        <MenuItem key={0} value={0}>
                                            No users available
                                        </MenuItem>
                                }

                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel >Modules</InputLabel>
                            <Select
                                multiple
                                value={selectedModules}
                                onChange={handleModuleChange}
                                input={<Input />}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                }}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {(selected as number[]).map(i => (
                                            <Chip key={`module-chip-${i}`} label={`${modules?.find(m => m.id === i)?.name}`} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                            >
                                {
                                    modules && modules.length > 0 ?
                                        modules.map(m => (
                                            <MenuItem key={`module-${m.id}`} value={m.id}>
                                                <Checkbox checked={selectedModules.indexOf(m.id ? m.id : -1) > -1} />
                                                <ListItemText primary={`${m.name}`} />
                                            </MenuItem>
                                        ))
                                        :
                                        <MenuItem key={0} value={0}>
                                            No modules available
                                        </MenuItem>
                                }

                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.formBottom}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Training Due Date"
                                    autoOk={true}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </MuiPickersUtilsProvider>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={repeat}
                                    onChange={handleRepeatChange}
                                    name="repeat"
                                    color="secondary"
                                />
                            }
                            label="Annual Repeat"
                        />
                        <Tooltip title={submitDisabled ? "Must select at least 1 user and 1 training module" : ""} placement="left" arrow>
                            <span>
                                <Button variant="contained" color="primary" disabled={submitDisabled || assignmentStatus.state === RequestState.Pending || showModal === true} size="large" onClick={handleAssignClick}>
                                        Assign training
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                </div>
                <Dialog
                    open={showModal}
                    onExit={handleCloseModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                >
                    {
                        assignmentStatus.state === RequestState.Success ?
                            <>
                                <DialogTitle id="alert-dialog-title">Training courses successfully assigned</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Courses
                                    </DialogContentText>
                                    <div>
                                        {
                                            selectedModules?.length > 0 ?
                                                selectedModules.map(s => {
                                                    return <Chip key={`selected-module-chip-${s}`} label={`${modules?.find(m => m.id === s)?.name}`} className={classes.chip} />
                                                }) :
                                                null
                                        }
                                    </div>
                                </DialogContent>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Staff
                                    </DialogContentText>
                                    <div>
                                    {
                                        selectedPersons?.length > 0 ?
                                            selectedPersons.map(s => {
                                                return <Chip key={`selected-persons-chip-${s}`} label={`${people?.find(p => p.userName === s)?.firstName} ${people?.find(p => p.userName === s)?.surname}`} className={classes.chip} />
                                            }) :
                                            null
                                    }
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
                                        Ok
                                    </Button>
                                </DialogActions>
                            </>
                            :
                            <>
                                <DialogTitle id="alert-dialog-title">Error assigning training courses</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        An error occured preventing training courses from being assigned
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary" autoFocus>
                                        Ok
                                    </Button>
                                </DialogActions>
                            </>
                    }
                </Dialog>
            </div>
        </div>

        )

}

export default TrainingAssignment;
