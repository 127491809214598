import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import createReduxStore from './store/createStore';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { theme } from './styles/Themes';
import * as Sentry from "@sentry/react";
//import { Integrations } from "@sentry/tracing";
import * as StaffTrainingDtos from './dtos/StaffTrainingDtos';
import { RequestState } from './types/RequestState';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const userResponse = global.AUTH as StaffTrainingDtos.GetAuthenticatedPersonResponse;

if (!userResponse || !userResponse.person) {
    if (global.location.search) {
        global.location.href = '/auth/openiddict' + global.location.search;
    } else {
        global.location.href = '/auth/openiddict';
    }
}

const history = createBrowserHistory();

//const reduxPreloadState: any = (window as any).REDUX_PRELOAD_STATE;
export const store = createReduxStore(history, {
    personState: {
        authenticatedPerson: userResponse?.person,
        loadState: {
            state: RequestState.Success
        }
    }
});

//Sentry.init({
//    dsn: "https://55c8206ba1024b0dbc54a70f0b48eeec@o483042.ingest.sentry.io/5534203",
//    integrations: [
//        new Integrations.BrowserTracing(),
//    ],
//    tracesSampleRate: 1.0,
//});

ReactDOM.render(
        <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App  />
            </ThemeProvider>
            </ConnectedRouter>
        </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
