/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains all the public asynchronous routes used in the application.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to allow for the use of function component.
 */
import * as React from 'react';

import { IRoute } from '..';


/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


export const AsyncStaffWrapperComponent = React.lazy(() => import('../../pages/Staff/Wrapper'));

export const AsyncStaffHomeComponent = React.lazy(() => import('../../pages/Staff/Home')); 

export const AsyncStaffAssessmentComponent = React.lazy(() => import('../../pages/Staff/Assessment'));

export const AsyncStaffResultsComponent = React.lazy(() => import('../../pages/Staff/Results'));

const staffRoute: IRoute = {
    component: AsyncStaffWrapperComponent,
    path: '/staff',
    requiresAuthentication: true,
    childRoutes: {
        home: {
            component: AsyncStaffHomeComponent,
            path: '/staff/home'
        },
        assessment: {
            component: AsyncStaffAssessmentComponent,
            path: '/staff/assessment'
        },
        results: {
            component: AsyncStaffResultsComponent,
            path: '/staff/results'
        }
    }
}

export default staffRoute;