
import * as StaffTrainingDtos from '../../dtos/StaffTrainingDtos'
import { IRequestState } from '../../types/IRequestState'
import { RequestState } from '../../types/RequestState';

import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import { createGetRequest } from '../../helpers/createRequest';
import { Epic, ofType, combineEpics } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import createAction from '../../helpers/createAction';
import { of } from 'rxjs';

interface IInitialPersonState {
    authenticatedPerson?: StaffTrainingDtos.Person;
    people?: StaffTrainingDtos.Person[];
    peopleWithTraining?: StaffTrainingDtos.Person[];
    loadState: IRequestState;
    loadWithTrainingState: IRequestState;
    loadAuthenticatedState: IRequestState;
}

export interface IPersonState {
    personState: IInitialPersonState;
}

const typeNameSpace = '@@person'

export const types = {
    LOAD: `${typeNameSpace}/LOAD`,
    LOAD_SUCCESS: `${typeNameSpace}/LOAD_SUCCESS`,
    LOAD_FAILURE: `${typeNameSpace}/LOAD_FAILURE`,
    LOAD_WITH_TRAINING: `${typeNameSpace}/LOAD_WITH_TRAINING`,
    LOAD_WITH_TRAINING_SUCCESS: `${typeNameSpace}/LOAD_WITH_TRAINING_SUCCESS`,
    LOAD_WITH_TRAINING_FAILURE: `${typeNameSpace}/LOAD_WITH_TRAINING_FAILURE`,
    LOAD_AUTHENTICATED: `${typeNameSpace}/LOAD_AUTHENTICATED`,
    LOAD_AUTHENTICATED_SUCCESS: `${typeNameSpace}/LOAD_AUTHENTICATED_SUCCESS`,
    LOAD_AUTHENTICATED_FAILURE: `${typeNameSpace}/LOAD_AUTHENTICATED_FAILURE`,
    CLEAR: `${typeNameSpace}/CLEAR`,
    CLEAR_AUTHENTICATED: `${typeNameSpace}/CLEAR_AUTHENTICATED`,
};


export const initialState: IInitialPersonState = {
    authenticatedPerson: undefined,
    people: undefined,
    peopleWithTraining: undefined,
    loadState: {
        state: RequestState.None
    },
    loadWithTrainingState: {
        state: RequestState.None
    },
    loadAuthenticatedState: {
        state: RequestState.None
    }
}

const personReducer = createReducer<IInitialPersonState>({}, initialState);

export const personActions = {

    load: createAction(types.LOAD, () => ({})),

    loadSuccess: createAction(types.LOAD_SUCCESS,
        (response: StaffTrainingDtos.GetPeopleResponse) => ({ response })
    ),
    loadFailure: createAction(types.LOAD_FAILURE,
        (response: StaffTrainingDtos.GetPeopleResponse) => ({ response })
    ),

    loadWithTraining: createAction(types.LOAD_WITH_TRAINING,
        ((outstanding?: boolean, completed?: boolean, future?: boolean, modules?: boolean) => ({ outstanding, completed, future, modules }))),

    loadWithTrainingSuccess: createAction(types.LOAD_WITH_TRAINING_SUCCESS,
        (response: StaffTrainingDtos.GetPeopleWithTrainingResponse) => ({ response })
    ),
    loadWithTrainingFailure: createAction(types.LOAD_WITH_TRAINING_FAILURE,
        (response: StaffTrainingDtos.GetPeopleWithTrainingResponse) => ({ response })
    ),

    loadAuthenticated: createAction(types.LOAD_AUTHENTICATED, () => ({})),

    loadAuthenticatedSuccess: createAction(types.LOAD_AUTHENTICATED_SUCCESS,
        (response: StaffTrainingDtos.GetAuthenticatedPersonResponse) => ({ response })
    ),
    loadAuthenticatedFailure: createAction(types.LOAD_AUTHENTICATED_FAILURE,
        (response: StaffTrainingDtos.GetAuthenticatedPersonResponse) => ({ response })
    ),

    clear: createAction(types.CLEAR,
        () => ({})
    ),
    clearAuthenticated: createAction(types.CLEAR_AUTHENTICATED,
        () => ({})
    )
}

personReducer.on(personActions.load, (state) => (
    update(
        state,
        {
            people: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

personReducer.on(personActions.loadSuccess, (state, payload) => (
    update(
        state,
        {
            people: {
                $set: payload.response.people
            },
            loadState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

personReducer.on(personActions.loadFailure, (state, payload) => (
    update(
        state,
        {
            people: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

personReducer.on(personActions.loadWithTraining, (state) => (
    update(
        state,
        {
            peopleWithTraining: {
                $set: undefined
            },
            loadWithTrainingState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

personReducer.on(personActions.loadWithTrainingSuccess, (state, payload) => (
    update(
        state,
        {
            peopleWithTraining: {
                $set: payload.response.people
            },
            loadWithTrainingState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

personReducer.on(personActions.loadWithTrainingFailure, (state, payload) => (
    update(
        state,
        {
            peopleWithTraining: {
                $set: undefined
            },
            loadWithTrainingState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

personReducer.on(personActions.loadAuthenticated, (state) => (
    update(
        state,
        {
            authenticatedPerson: {
                $set: undefined
            },
            loadAuthenticatedState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )        
));

personReducer.on(personActions.loadAuthenticatedSuccess, (state, payload) => (
    update(
        state,
        {
            authenticatedPerson: {
                $set: payload.response.person
            },
            loadAuthenticatedState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

personReducer.on(personActions.loadAuthenticatedFailure, (state, payload) => (
    update(
        state,
        {
            authenticatedPerson: {
                $set: undefined
            },
            loadAuthenticatedState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

personReducer.on(personActions.clear, (state) => (
    update(
        state,
        {
            people: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.None,
                    responseStatus: undefined
                }
            },
            peopleWithTraining: {
                $set: undefined
            },
            loadWithTrainingState: {
                $set: {
                    state: RequestState.None,
                    responseStatus: undefined
                }
            }
        }
    )));
personReducer.on(personActions.clearAuthenticated, (state) => (
    update(
        state,
        {
            authenticatedPerson: {
                $set: undefined
            },
            loadAuthenticatedState: {
                $set: {
                    state: RequestState.None,
                    responseStatus: undefined
                }
            },
        }
    )));

export const personApi = {
    load: createGetRequest(
        StaffTrainingDtos.GetPeople,
        () => ({})
    ),
    loadWithTraining: createGetRequest(
        StaffTrainingDtos.GetPeopleWithTraining,
        (outstanding?: boolean, completed?: boolean, future?: boolean, modules?: boolean) => ({
            outstanding: outstanding,
            completed: completed,
            future: future,
            modules: modules,
        })
    ),
    loadAuthenticated: createGetRequest(
        StaffTrainingDtos.GetAuthenticatedPerson,
        () => ({})
    ),
}

export const loadEpic: Epic<ReturnType<typeof personActions.load>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(personActions.load.getType()),
    mergeMap(action =>
        personApi.load()
            .pipe(
                mergeMap(response =>
                    of(
                        personActions.loadSuccess(response)
                    )
                ),
                catchError(e => of(personActions.loadFailure(e)))
            )
    )
);

export const loadWithTrainingEpic: Epic<ReturnType<typeof personActions.loadWithTraining>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(personActions.loadWithTraining.getType()),
    mergeMap(action =>
        personApi.loadWithTraining(action.payload.outstanding, action.payload.completed, action.payload.future, action.payload.modules)
            .pipe(
                mergeMap(response =>
                    of(
                        personActions.loadWithTrainingSuccess(response)
                    )
                ),
                catchError(e => of(personActions.loadWithTrainingFailure(e)))
            )
    )
);

export const loadAuthenticatedEpic: Epic<ReturnType<typeof personActions.loadAuthenticated>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(personActions.loadAuthenticated.getType()),
    mergeMap(action =>
        personApi.loadAuthenticated()
            .pipe(
                mergeMap(response =>
                    of(
                        personActions.loadAuthenticatedSuccess(response)
                    )
                ),
                catchError(e => of(personActions.loadAuthenticatedFailure(e)))
            )
    )
);

export const personEpics = combineEpics(
    loadEpic,
    loadWithTrainingEpic,
    loadAuthenticatedEpic
);

export default personReducer;