import React, { useEffect, useMemo, useState, useRef } from 'react';
import { makeStyles, Theme, LinearProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { IStoreState } from '../../store/createStore';
import { userModuleActions } from '../../store/reducers/userModule';
import { assessmentActions } from '../../store/reducers/assessment';

import OutstandingModules from '../../components/OutstandingModules';
import CompletedModules from '../../components/CompletedModules';
import * as StaffTrainingDtos from '../../dtos/StaffTrainingDtos';
import { DateTime } from 'luxon';
import { Link } from '../../components/Link';
import { BannerHeading } from '../../components/BannerHeading';
import { attemptsRemaining, timeLimit, timeToRetry, initialTimeLimit } from '../../utils/userModule';
import routes from '../../routes/routes';
import Timer from '../../components/Timer';
import { useLocation, useHistory } from 'react-router';
import { ResponseStatus } from '@servicestack/client';
import { RequestState } from '../../types/RequestState';
import globalStyles from '../../styles/globalStyles';
import Button from '../../components/Button';
import { useDeferredValue } from '../../utils/defferedValue';
import { routerActions } from 'connected-react-router';

interface IHomeProps {
}

interface IHomeStyleProps {
    showTimer: boolean
}

const useStyles = makeStyles<Theme, IHomeStyleProps>((theme) => ({
    
    trainingInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    oustandingModuleWrapper: {
        width: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: theme.spacing(12),
        marginBottom: theme.spacing(10)

    },
    userSummary: {
        boxShadow: "0 0 5px 1px #0000004a",
        padding: "16px 32px 16px 32px",
        width: "80%"
    },
    summaryTable: {
        width: "100%",
        borderCollapse: "collapse",
        boxSizing: "content-box",
        fontSize: "medium"
    },
    leftColumn: {
        fontWeight: "bold",
        textAlign: "left",
        height: theme.spacing(4),
        alignItems: "center",
        paddingLeft: theme.spacing(2),
    },
    rightColumn: {
        textAlign: "left",
        alignItems: "center",
        paddingRight: theme.spacing(2),
        height: theme.spacing(4),
        width: "50%"
    },
    summaryHeader: {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(8),
        textAlign: "center"
    },
    greyRow: {
        "& td": {
            backgroundColor: theme.palette.grey[200]
        }
    },
    moduleList: {
        boxShadow: "0 0 5px 1px #0000004a",
    },
    assessmentWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "50%"
    },
    timeLimit: {
        fontSize: "20px",
        "& span": {
            color: theme.palette.secondary.main
        },
        display: "flex"
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        color: "white",
        textDecoration: "none",
        width: "max-content",
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            transitionProperty: 'background-color',
            transitionDuration: '100ms',
            transitionTimingFunction: 'ease-in-out',
            WebkitTransitionProperty: 'background-color',
            WebkitTransitionDuration: '100ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        transitionProperty: 'background-color',
        transitionDuration: '100ms',
        transitionTimingFunction: 'easi-in-out',
        WebkitTransitionProperty: 'background-color',
        WebkitTransitionDuration: '100ms',
        WebkitTransitionTimingFunction: 'ease-in-out',
    },
    disabledButton: {
        backgroundColor: theme.palette.disabled.main,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        color: theme.palette.grey[200],
        textDecoration: "none",
        width: "max-content",
    },
    timerContaininer: {
        display: (props: IHomeStyleProps) => props.showTimer ? "flex" : "none",
        color: theme.palette.secondary.main
    },
    timeRemainingContaininer: {
        display: (props: IHomeStyleProps) => props.showTimer ? "none" : "flex"
    },
    module: {
        padding: "4px 16px 4px 16px",
        width: theme.spacing(64),
        display: "flex",
        justifyContent: "space-between"
    },
    title: {
        color: theme.palette.common.black,
        fontSize: "26px",
    },
}))


const Home: React.FunctionComponent<IHomeProps> = () => {

    const outstandingModules = useSelector((state: IStoreState) => state.userModuleState.oustandingModules)
    const completedModulesInit = useSelector((state: IStoreState) => state.userModuleState.completedModules)

    const oustandingModulesState = useSelector((state: IStoreState) => state.userModuleState.loadOustandingState);
    const completedModulesState = useSelector((state: IStoreState) => state.userModuleState.loadCompletedState);

    const dispatch = useDispatch();
    const location = useLocation();
    const completedRef = useRef(document.createElement("div"));
    const history = useHistory();

    const [canStartAssessment, setCanStartAssessment] = useState(true);

    useEffect(() => {
        if (assessmentActions.clear) {
            dispatch(assessmentActions.clear())
        }
        if (userModuleActions.loadOutstanding) {
            dispatch(userModuleActions.loadOutstanding(false))
        }
        if (userModuleActions.loadCompleted) {
            dispatch(userModuleActions.loadCompleted(false))
        }
    }, [])

    const completedModules = useMemo(() => {
        let tempCompletedModules: StaffTrainingDtos.UserModuleAttempt[] = [];
        if (completedModulesInit) {
            completedModulesInit.forEach(m => {
                if (outstandingModules?.findIndex(o => o.id === m.id) === -1) {
                    tempCompletedModules.push(m);
                }
            });  
        }
        return tempCompletedModules;
    }, [outstandingModules, completedModulesInit])

    useEffect(() => {
        if (completedModules && completedModules.length > 0 && location.hash.includes("completedTraining") && completedRef && completedRef.current) {
            completedRef.current.scrollIntoView();
        }
    }, [completedModules])

    
    const initialTimeRemaining = useMemo(() => {
        if (outstandingModules && outstandingModules.length > 0) {
            return Math.floor(initialTimeLimit(outstandingModules) / 60)
        }
        return 0;
    }, [outstandingModules])

    const allowedTime = useMemo(() => {
        if (outstandingModules && outstandingModules.length > 0 && outstandingModules[0].finishTime && outstandingModules[0].startTime) {
            return timeToRetry(outstandingModules[0].startTime, outstandingModules[0].finishTime, initialTimeRemaining);
        }
    }, [outstandingModules, initialTimeRemaining])

    const classes = useStyles({ showTimer: !!allowedTime })
    const globalClasses = globalStyles({}); 

    const mostRecentModules = useMemo(() => {
        var result: StaffTrainingDtos.UserModuleAttempt[] | null = null;
        if (completedModules && completedModules.length > 0) {
            completedModules.sort((a, b) => { return new Date(b.finishTime ? b.finishTime.toString() : "").valueOf() - new Date(a.finishTime ? a.finishTime.toString() : "").valueOf() });

            let mostRecentFinishTime: string = completedModules[0].finishTime ? completedModules[0].finishTime : "";

            result = completedModules.filter(m => m.finishTime === mostRecentFinishTime)
        }
        return result;
    }, [completedModules]);

    const canAttemptTraining = useMemo(() => {
        let canStart = false;
        if (outstandingModules) {
             canStart = true;
        }
        
        return canStart;
    }, [outstandingModules, canStartAssessment])

    const disableStartButton = () => {
        setCanStartAssessment(false);
        if (assessmentActions.clear) {
            dispatch(assessmentActions.clear())
        }
        if (userModuleActions.loadOutstanding) {
            dispatch(userModuleActions.loadOutstanding(false))
        }
        if (userModuleActions.loadCompleted) {
            dispatch(userModuleActions.loadCompleted(false))
        }
    }

    const trainingInProgress = useMemo(() => {
        let inProgress = false;
        if (outstandingModules && outstandingModules.length > 0) {
            inProgress = !!outstandingModules[0].startTime;
        }

        return inProgress;
    }, [outstandingModules])

    useEffect(() => {
        if (trainingInProgress && outstandingModules && outstandingModules.length > 0 && !outstandingModules[0].finishTime) {
            dispatch(routerActions.push("/staff/assessment/resume"));
        }
    }, [outstandingModules, trainingInProgress])
    
    const resetTraining = () => {
        if (userModuleActions.resetAttempts) {
            dispatch(userModuleActions.resetAttempts())
        }
    }

    const renderCompletedTrainingSummary = (userModule: StaffTrainingDtos.UserModuleAttempt, index: number, mostRecentModules: StaffTrainingDtos.UserModuleAttempt[]): JSX.Element => {
        return (
            <>
                {
                    index === 0 ?
                    <td style={{ paddingTop: "32px" }} className={classes.leftColumn}>
                        {`Last Attempted Module${mostRecentModules.length > 1 ? "s" : ""}:`}
                    </td>
                        : <td></td>
                }

                {
                    index === 0 ?
                        <td style={{ paddingTop: "32px" }} className={classes.rightColumn}>
                            {userModule.module?.name}
                        </td>
                        :
                        <td className={classes.rightColumn}>
                            {userModule.module?.name}
                        </td>
                }
            </>
            )
    }

    const renderLastAttempDateSummary = (mostRecentModules: StaffTrainingDtos.UserModuleAttempt): JSX.Element => {
        return (
            <>
                <td className={classes.leftColumn}>
                        Last Attempt Date:
                </td>
                <td className={classes.rightColumn}>
                    {
                        mostRecentModules.finishTime ?
                            DateTime.fromISO(mostRecentModules.finishTime).toFormat("dd'/'MM'/'yyyy' 'hh':'mm' 'a")
                            : null
                    }
                </td>
            </>
        )
    }

    const showLoading = useDeferredValue( oustandingModulesState?.state === RequestState.Pending || completedModulesState?.state === RequestState.Pending);

    return (
        <div className={globalClasses.root}>
            <div className={globalClasses.body}>
                {
                    showLoading && (
                        <LinearProgress color="secondary" style={{ width: '100%' }} />
                        )
                }
                <div className={classes.trainingInfo}>
                    <div className={classes.summaryHeader}>
                        Welcome
                    </div>
                    <div style={{ textAlign: "justify" }}>
                        <p>This is the staff training portal</p>
                        <p>On this site you can complete assigned training modules related to your work on studies at Breast Cancer Trials</p>
                        <p>These training modules are time assessments that must be completed in one sitting</p>
                        <p>On this page you can view modules that are outstanding along with results from previously compelted modules</p>
                    </div>

                    <div className={classes.summaryHeader}>
                        Training Summary
                    </div>
                    <div className={classes.userSummary}>
                        <table className={classes.summaryTable}>
                            <tbody>
                                <tr>
                                    <td className={classes.leftColumn}>
                                        Outstanding Training Modules:
                                    </td>
                                    <td className={classes.rightColumn}>
                                        {outstandingModules?.length}
                                    </td>
                                </tr>
                                {
                                    mostRecentModules && mostRecentModules.length > 0 ?
                                        <>
                                            {
                                                mostRecentModules?.map((userModule, index) => {
                                                    return (
                                                        <React.Fragment key={`complete-${userModule.id}`}>
                                                            <tr>
                                                                {renderCompletedTrainingSummary(userModule, index, mostRecentModules)}
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                            <tr>
                                                {renderLastAttempDateSummary(mostRecentModules[0])}
                                            </tr>
                                            
                                        </>
                                        : null
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className={classes.oustandingModuleWrapper} >
                        {
                            outstandingModules && outstandingModules.length > 0 ?
                                <>

                                    <OutstandingModules
                                        outstandingModules={outstandingModules}
                                    />

                                    <div className={classes.assessmentWrapper}>
                                        {
                                            canAttemptTraining ?
                                                <Button
                                                    handleClick={() => history.push(`/staff/assessment${trainingInProgress ? "/resume" : "/begin"}`)}
                                                    className={canAttemptTraining ? classes.button : classes.disabledButton}
                                                    disable={canAttemptTraining ? false : true}
                                                >
                                                    {
                                                        trainingInProgress ?
                                                            "Resume " :
                                                            "View "
                                                    }
                                                    Assessment
                                                </Button>
                                                : null
                                        }
                                        
                                        <div className={classes.timeLimit}>
                                            {
                                                canAttemptTraining ?
                                                    <>
                                                        Time allowed{trainingInProgress ? " to resume:" : ":"}&nbsp;
                                                        {
                                                            <>
                                                                <div className={classes.timerContaininer}>
                                                                    <Timer
                                                                        initialSeconds={Math.floor(allowedTime ? allowedTime : 0)}
                                                                        run={trainingInProgress ? trainingInProgress : false}
                                                                        onTimerFinish={disableStartButton}
                                                                        onTimerStart={undefined}
                                                                    />
                                                                </div>
                                                                <span className={classes.timeRemainingContaininer}>
                                                                    {initialTimeRemaining} minute{initialTimeRemaining && initialTimeRemaining > 1 ? "s" : ""}
                                                                </span>
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <div style={{ textAlign: "center" }}>
                                                        No attempts remaing on these modules! <br />
                                                        Contact Breast Cancer Trials for instructions
                                                        </div>
                                            }
                                            
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={classes.timeLimit}>
                                            You currently have no outstanding training
                                    </div>
                                </>
                        }
                    </div>
                    <div style={{ width: "100%" }} ref={completedRef} id="completedTraining">
                        {
                            completedModules && completedModules.length > 0 ?
                                <CompletedModules
                                    completedModules={completedModules}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
        );
}

export default Home;