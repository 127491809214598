import React from 'react';

import * as StaffTrainingDtos from  '../dtos/StaffTrainingDtos'
import { makeStyles, Theme } from '@material-ui/core';
import Question from './Question';
import classNames from 'classnames';
import { modulePassed } from '../utils/userModule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import routes from '../routes/routes';
import { useLocation } from 'react-router';

interface IModulelistProps {
    completedModules: StaffTrainingDtos.UserModuleAttempt[];
}

interface IModuleListStyleProps {
    disable?: boolean;
}

const useStyles = makeStyles<Theme, IModuleListStyleProps>((theme: Theme) => ({
    root: {
        width: "100%"
    },
    question: {
        margin: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    trainingInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginBottom: theme.spacing(6)
    },
    resultsContaininer: {
        boxShadow: "0 0 5px 1px #0000004a",
        width: "80%",
        marginBottom: theme.spacing(8)
    },
    resultsTable: {
        borderCollapse: "collapse",
        width: "100%",
        "& tbody": {
            display: "block",
            paddingBottom: theme.spacing(2)
        }
    },
    resultsTableRow: {
        display: "flex",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        justifyContent: "space-evenly",
        "& td": {
            minWidth: "15%",
            textAlign: "center",
            fontSize: "14pt"
        },
    },
    greyRow: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
    },
    resultsTableHeader: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        margin: theme.spacing(2),
        marginBottom: theme.spacing(4),
        display: "flex",
        fontWeight: "bold",
        justifyContent: "space-evenly",
        "& td": {
            minWidth: "15%",
            textAlign: "center",
            fontSize: "14pt"
        }
    },
    leftColumn: {
        width: "40%"
    },
    summaryHeader: {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        margin: "32px 0"
    },
    module: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "8px 32px 8px 32px",
        border: "solid 1px",
        "& span": {
            fontSize: "20pt",
            fontWeight: "normal"
        }
    },
    moduleHeader: {
        width: "45%",
        backgroundColor: theme.palette.common.white,
        border: "none",
        color: theme.palette.grey[600],
    },
    passIcon: {
        color: theme.palette.success.main
    },
    failIcon: {
        color: theme.palette.error.dark
    }
}))



export const CompletedModules: React.FunctionComponent<IModulelistProps> = ({ completedModules }) => {

    const classes = useStyles({});
    const location = useLocation();

    const passed = (userModule: StaffTrainingDtos.UserModuleAttempt): boolean => {
        return modulePassed(userModule);
    }

    return (
        <div className={classes.root}>
            {
                <div className={classes.trainingInfo}>
                    <div className={classes.summaryHeader}>
                        {
                            location.pathname === routes.staff.childRoutes?.results.path ?
                                "Results"
                                :
                            "Completed Training Modules"

                        }
                    </div>

                    <div className={classes.resultsContaininer}>
                        <table className={classes.resultsTable}>
                            <tbody>
                                <tr className={classNames(classes.resultsTableHeader, classes.greyRow)}>
                                    <td className={classes.leftColumn}>
                                        Course
                                    </td>
                                    <td>
                                        Score
                                    </td>
                                    <td>
                                        Required
                                    </td>
                                    <td>
                                        Passed
                                    </td>
                                    <td>
                                        Date
                                    </td>
                                </tr>
                                {
                                    completedModules && completedModules.length > 0 ?
                                        completedModules.map((m, index) => {
                                            return <tr key={`completedModule-${index}`} className={classNames(classes.resultsTableRow, index % 2 ? classes.greyRow : null)}>
                                                <td className={classes.leftColumn}>{m.module?.name}</td>
                                                <td>{m.score} / {m.module?.rules?.numberOfQuestions}</td>
                                                <td>
                                                    {
                                                        m.module?.rules?.numberOfQuestions && m.module?.rules?.passMark ?
                                                            Math.ceil(m.module?.rules?.numberOfQuestions * m.module?.rules?.passMark / 100)
                                                            : null
                                                }</td>
                                                <td>
                                                    {
                                                        passed(m) ?
                                                            <span className={classes.passIcon}><FontAwesomeIcon icon={faCheck} fixedWidth /></span>
                                                            : <span className={classes.failIcon}><FontAwesomeIcon icon={faTimes} fixedWidth /></span>
                                                    }
                                                </td>
                                                <td>{m.finishTime ? new Date(m.finishTime).toLocaleDateString('en-GB') : ""}</td>
                                            </tr>
                                        })
                                        : null

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
}

export default CompletedModules;