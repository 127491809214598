

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const theme = responsiveFontSizes(createMuiTheme({
    palette: {
        primary: {
            light: "#6f39ab",
            main: "#562c85",
            dark: "#4f287b",
        },
        secondary: {
            main: "#EC008C",
        },
        disabled: {
            main: "#8668a7",
        },
        success: {
            light: "#b9e4ba",
            main: "#69b96c",
            dark: "#388e3c",
        },
        error: {
            light: "#ffb7b7",
            main: "#ffa099",
            dark: "#d32f2f",
        }
    },

    typography: {
        h1: {
            fontSize: '2.5rem'
        },
        h2: {
            fontSize: '1.5rem'
        },
        h3: {
            fontSize: '1.25rem'
        },
        h4: {
            fontSize: '1.20rem'
        },
        h5: {
            fontSize: '1.15rem'
        },
        h6: {
            fontSize: '1.10rem'
        }
    }
}));

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        disabled: Palette['primary'];
    }
    interface PaletteOptions {
        disabled: PaletteOptions['primary'];
    }
}

export default theme;