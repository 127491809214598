import React from 'react';

import * as StaffTrainingDtos from  '../dtos/StaffTrainingDtos'
import { makeStyles, Theme, Button } from '@material-ui/core';
import routes from '../routes/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faClipboardList, faClipboardCheck, faTasks } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import Link from './Link';


interface IMenuBarProps {
    admin: boolean
}

interface IStaffMenuBarProps {

}

interface IAdminMenuBarProps {
}

interface IMenuBarStyleProps {

}

const useStyles = makeStyles<Theme, IMenuBarStyleProps>((theme: Theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center"
    },
    menu: {
        display: "flex",
        justifyContent: "center",
        width: "80%",
        zIndex: 2
    },
    staffMenu: {
        display: "flex",
        height: theme.spacing(1),
        width: "80%",
        zIndex: 2
    },

    navButton: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: "18px",
        color: "white",
        textDecoration: "none",
        width: "max-content",
        borderRadius: "0px",
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            transitionProperty: 'background-color',
            transitionDuration: '100ms',
            transitionTimingFunction: 'ease-in-out',
            WebkitTransitionProperty: 'background-color',
            WebkitTransitionDuration: '100ms',
            WebkitTransitionTimingFunction: 'ease-in-out',
        },
        transitionProperty: 'background-color',
        transitionDuration: '100ms',
        transitionTimingFunction: 'easi-in-out',
        WebkitTransitionProperty: 'background-color',
        WebkitTransitionDuration: '100ms',
        WebkitTransitionTimingFunction: 'ease-in-out',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    navButtonActive: {
        backgroundColor: theme.palette.primary.light
    }
}))


export const MenuBar: React.FunctionComponent<IMenuBarProps> = ({ admin }) => {

    const classes = useStyles({});

    return (
        <div className={classes.root}>
            {
                admin ?
                    <AdminMenuBar /> 
                    : <StaffMenuBar />
            }
        </div>
    );
}

const StaffMenuBar: React.FunctionComponent<IStaffMenuBarProps> = () => {

    const classes = useStyles({});

    return (
        <div className={classes.staffMenu}>
        </div>
    );
}

const AdminMenuBar: React.FunctionComponent<IAdminMenuBarProps> = () => {

    const classes = useStyles({});

    const location = useLocation();

    return (
        <div className={classes.menu}>
            <Link
                className={location.pathname === routes.admin.childRoutes?.home.path ? classNames(classes.navButton, classes.navButtonActive) : classes.navButton}
                to={routes.admin.childRoutes?.home.path}
            >
                <span className={classes.icon}><FontAwesomeIcon icon={faHome} fixedWidth /></span>Home
            </Link>
            <Link
                className={location.pathname === routes.admin.childRoutes?.modules.path ? classNames(classes.navButton, classes.navButtonActive) : classes.navButton}
                to={routes.admin.childRoutes?.modules.path}
            >
                <span className={classes.icon}><FontAwesomeIcon icon={faClipboardCheck} fixedWidth /></span>Staff Attempts
            </Link>
            <Link
                className={location.pathname === routes.admin.childRoutes?.assignment.path ? classNames(classes.navButton, classes.navButtonActive) : classes.navButton}
                to={routes.admin.childRoutes?.assignment.path}
            >
                <span className={classes.icon}><FontAwesomeIcon icon={faTasks} fixedWidth /></span>Assign Training
            </Link>
        </div>
    );
}

export default Text;