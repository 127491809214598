/* Options:
Date: 2020-11-02 13:54:23
Version: 5.92
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44376

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: GetInstitutionPersonnelEmailAddresses,InstitutionPersonnelEmailAddressesResponse,SendRegistrationEmail,SendRandomisationEmail
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IGet
{
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class Person
{
    public userName: string;
    public firstName: string;
    public surname: string;
    public isAdmin: boolean;
    public outstandingModules: UserModuleAttempt[];
    public completedModules: UserModuleAttempt[];
    public futureModules: UserModuleAttempt[];

    public constructor(init?: Partial<Person>) { (Object as any).assign(this, init); }
}

export class QuestionResponse
{
    public id?: number;
    public questionId: number;
    public value: number;
    public text: string;
    public enteredDate: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public startDate?: string;
    public deprecatedDate?: string;

    public constructor(init?: Partial<QuestionResponse>) { (Object as any).assign(this, init); }
}

export class Question
{
    public id?: number;
    public moduleId: number;
    public rank: number;
    public correctResponse: number;
    public text: string;
    public enteredDate: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public startDate?: string;
    public deprecatedDate?: string;
    public responses: QuestionResponse[];

    public constructor(init?: Partial<Question>) { (Object as any).assign(this, init); }
}

export class ModuleRules
{
    public id?: number;
    public moduleId: number;
    public numberOfAttempts: number;
    public passMark: number;
    public minutesAllowed: number;
    public numberOfQuestions: number;
    public enteredDate: string;
    public enteredBy: string;
    public startDate?: string;
    public deprecatedDate?: string;

    public constructor(init?: Partial<ModuleRules>) { (Object as any).assign(this, init); }
}

export class Module
{
    public id?: number;
    public name: string;
    public deprecatedDate?: string;
    public enteredDate: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public questions: Question[];
    public rules: ModuleRules;

    public constructor(init?: Partial<Module>) { (Object as any).assign(this, init); }
}

export class UserQuestionResponse
{
    public id?: number;
    public attemptId: number;
    public questionId: number;
    public responseId: number;

    public constructor(init?: Partial<UserQuestionResponse>) { (Object as any).assign(this, init); }
}

export class UserModuleAttempt
{
    public id?: number;
    public userName: string;
    public moduleId: number;
    public dueDate: string;
    public startTime?: string;
    public finishTime?: string;
    public score?: number;
    public status?: AssessmentStatus; 
    public enteredDate: string;
    public enteredBy: string;
    public userQuestionResponses: UserQuestionResponse[];
    public module: Module;
    public passed?: boolean;

    public constructor(init?: Partial<UserModuleAttempt>) { (Object as any).assign(this, init); }
}

export enum AssessmentStatus
{
    Preview = 'Preview',
    InProgress = 'InProgress',
    Complete = 'Complete',
    TimeLimitReached = 'TimeLimitReached',
    Exited = "Exited",
    Cancelled = "Cancelled"
}

export class CreateDevSessionResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateDevSessionResponse>) { (Object as any).assign(this, init); }
}

export class GetAuthenticatedPersonResponse
{
    public responseStatus: ResponseStatus;
    public person: Person;

    public constructor(init?: Partial<GetAuthenticatedPersonResponse>) { (Object as any).assign(this, init); }
}

export class GetPeopleResponse {
    public responseStatus: ResponseStatus;
    public people: Person[];

    public constructor(init?: Partial<GetPeopleResponse>) { (Object as any).assign(this, init); }
}

export class GetPeopleWithTrainingResponse {
    public responseStatus: ResponseStatus;
    public people: Person[];

    public constructor(init?: Partial<GetPeopleWithTrainingResponse>) { (Object as any).assign(this, init); }
}

export class GetModulesResponse
{
    public responseStatus: ResponseStatus;
    public modules: Module[];

    public constructor(init?: Partial<GetModulesResponse>) { (Object as any).assign(this, init); }
}

export class GetModulesByRoleResponse
{
    public responseStatus: ResponseStatus;
    public modules: Module[];

    public constructor(init?: Partial<GetModulesByRoleResponse>) { (Object as any).assign(this, init); }
}

export class GetUserModulesAuthResponse
{
    public responseStatus: ResponseStatus;
    public userModules: UserModuleAttempt[];

    public constructor(init?: Partial<GetUserModulesAuthResponse>) { (Object as any).assign(this, init); }
}

export class GetCompleteUserModulesResponse {
    public responseStatus: ResponseStatus;
    public userModules: UserModuleAttempt[];

    public constructor(init?: Partial<GetCompleteUserModulesResponse>) { (Object as any).assign(this, init); }
}

export class GetOutstandingUserModulesByAuthResponse
{
    public responseStatus: ResponseStatus;
    public userModules: UserModuleAttempt[];

    public constructor(init?: Partial<GetOutstandingUserModulesByAuthResponse>) { (Object as any).assign(this, init); }
}

export class GetCompletedUserModulesByAuthResponse
{
    public responseStatus: ResponseStatus;
    public userModules: UserModuleAttempt[];

    public constructor(init?: Partial<GetCompletedUserModulesByAuthResponse>) { (Object as any).assign(this, init); }
}

export class GetUserModulesByUserIdResponse
{
    public responseStatus: ResponseStatus;
    public userModules: UserModuleAttempt[];

    public constructor(init?: Partial<GetUserModulesByUserIdResponse>) { (Object as any).assign(this, init); }
}

export class GetQuestionsResponse
{
    public responseStatus: ResponseStatus;
    public questions: Question[];

    public constructor(init?: Partial<GetQuestionsResponse>) { (Object as any).assign(this, init); }
}

export class UpdateUsersResponseResponse
{
    public responseStatus: ResponseStatus;
    public userResponse: UserQuestionResponse;

    public constructor(init?: Partial<UpdateUsersResponseResponse>) { (Object as any).assign(this, init); }
}

export class LoadAssessmentResponse
{
    public responseStatus: ResponseStatus;
    public userModuleAttempts: UserModuleAttempt[];
    public status?: AssessmentStatus;
    public timeRemaining?: number;

    public constructor(init?: Partial<LoadAssessmentResponse>) { (Object as any).assign(this, init); }
}

export class StartAssessmentResponse
{
    public responseStatus: ResponseStatus;
    public status: AssessmentStatus;

    public constructor(init?: Partial<StartAssessmentResponse>) { (Object as any).assign(this, init); }
}

export class EndAssessmentResponse
{
    public responseStatus: ResponseStatus;
    public userModuleAttempts: UserModuleAttempt[];

    public constructor(init?: Partial<EndAssessmentResponse>) { (Object as any).assign(this, init); }
}

export class ResetMostRecentAttemptsResponse
{
    public responseStatus: ResponseStatus;
    public userModules: UserModuleAttempt[];

    public constructor(init?: Partial<ResetMostRecentAttemptsResponse>) { (Object as any).assign(this, init); }
}

export class AssignUserTrainingResponse {
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignUserTrainingResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

export class CreateDevSession implements IReturn<CreateDevSessionResponse>
{

    public constructor(init?: Partial<CreateDevSession>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateDevSessionResponse(); }
    public getTypeName() { return 'CreateDevSession'; }
}

// @Route("/api/auth-person", "GET")
export class GetAuthenticatedPerson implements IReturn<GetAuthenticatedPersonResponse>
{

    public constructor(init?: Partial<GetAuthenticatedPerson>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAuthenticatedPersonResponse(); }
    public getTypeName() { return 'GetAuthenticatedPerson'; }
}

// @Route("/api/people", "GET")
export class GetPeople implements IReturn<GetPeopleResponse>
{

    public constructor(init?: Partial<GetPeople>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPeopleResponse(); }
    public getTypeName() { return 'GetPeople'; }
}

// @Route("/api/people-with-training", "GET")
export class GetPeopleWithTraining implements IReturn<GetPeopleWithTrainingResponse>
{
    public outstanding?: boolean;
    public completed?: boolean;
    public future?: boolean;
    public modules?: boolean;

    public constructor(init?: Partial<GetPeople>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPeopleWithTrainingResponse(); }
    public getTypeName() { return 'GetPeopleWithTraining'; }
}

// @Route("/api/modules", "GET")
export class GetModules implements IReturn<GetModulesResponse>
{

    public constructor(init?: Partial<GetModules>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetModulesResponse(); }
    public getTypeName() { return 'GetModules'; }
}

// @Route("/api/modulesByRole/{roleId}", "GET")
export class GetModulesByRole implements IReturn<GetModulesByRoleResponse>
{
    /**
    * The roleId to retrieve modules for 
    */
    // @ApiMember(DataType="int", Description="The roleId to retrieve modules for ", IsRequired=true, Name="roleId", ParameterType="path")
    public roleId?: number;

    public constructor(init?: Partial<GetModulesByRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetModulesByRoleResponse(); }
    public getTypeName() { return 'GetModulesByRole'; }
}

// @Route("/api/userModulesAll", "GET")
export class GetUserModules implements IReturn<GetModulesByRoleResponse>
{

    public constructor(init?: Partial<GetUserModules>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetModulesByRoleResponse(); }
    public getTypeName() { return 'GetUserModules'; }
}

// @Route("/api/userModules-complete", "GET")
export class GetCompleteUserModules implements IReturn<GetCompleteUserModulesResponse>
{
    public includeQuestions: boolean | null;
    public failOnly: boolean | null;
    public userNames: string[];
    public moduleIds: number[];
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<GetCompleteUserModules>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCompleteUserModulesResponse(); }
    public getTypeName() { return 'GetCompleteUserModules'; }
}


// @Route("/api/userModules", "GET")
export class GetUserModulesByAuth implements IReturn<GetUserModulesAuthResponse>
{

    public constructor(init?: Partial<GetUserModulesByAuth>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserModulesAuthResponse(); }
    public getTypeName() { return 'GetUserModulesByAuth'; }
}

// @Route("/api/currentUserModules", "GET")
export class GetOutstandingUserModulesByAuth implements IReturn<GetOutstandingUserModulesByAuthResponse>
{
    public includeQuestions: boolean;

    public constructor(init?: Partial<GetOutstandingUserModulesByAuth>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetOutstandingUserModulesByAuthResponse(); }
    public getTypeName() { return 'GetOutstandingUserModulesByAuth'; }
}

// @Route("/api/completedUserModules", "GET")
export class GetCompletedUserModulesByAuth implements IReturn<GetCompletedUserModulesByAuthResponse>
{
    public includeQuestions: boolean;

    public constructor(init?: Partial<GetCompletedUserModulesByAuth>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCompletedUserModulesByAuthResponse(); }
    public getTypeName() { return 'GetCompletedUserModulesByAuth'; }
}

// @Route("/api/userModules/{userName}", "GET")
export class GetUserModulesByUserId implements IReturn<GetUserModulesByUserIdResponse>
{
    /**
    * The user to retrieve modules for 
    */
    // @ApiMember(DataType="int", Description="The user to retrieve modules for ", IsRequired=true, Name="userName", ParameterType="path")
    public userName: string;

    public constructor(init?: Partial<GetUserModulesByUserId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserModulesByUserIdResponse(); }
    public getTypeName() { return 'GetUserModulesByUserId'; }
}

// @Route("/api/questions", "GET")
// @Route("/api/questions/{includeDeprecated}", "GET")
// @Route("/api/questions/module/{moduleId}", "GET")
// @Route("/api/questions/module/{moduleId}/{includeDeprecated}", "GET")
export class GetQuestions implements IReturn<GetQuestionsResponse>
{
    /**
    * The moduleId to retrieve questions for
    */
    // @ApiMember(DataType="int", Description="The moduleId to retrieve questions for", Name="moduleId", ParameterType="path")
    public moduleId?: number;

    /**
    * Value determining whether or not to include deprecated quesitons in the query
    */
    // @ApiMember(DataType="bool", Description="Value determining whether or not to include deprecated quesitons in the query", Name="moduleId", ParameterType="path")
    public includeDeprecated?: boolean;

    public constructor(init?: Partial<GetQuestions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetQuestionsResponse(); }
    public getTypeName() { return 'GetQuestions'; }
}

// @Route("/api/update-users-response", "POST")
export class UpdateUsersResponse implements IReturn<UpdateUsersResponseResponse>, IPost
{
    /**
    * The users response to a question
    */
    // @ApiMember(DataType="object", Description="The users response to a question", IsRequired=true, Name="userResponse", ParameterType="body")
    public userResponse: UserQuestionResponse;

    public constructor(init?: Partial<UpdateUsersResponseResponse>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateUsersResponseResponse(); }
    public getTypeName() { return 'UpdateUsersResponse'; }
}

// @Route("/api/load-assessment", "GET")
export class LoadAssessment implements IReturn<LoadAssessmentResponse>, IGet
{

    public constructor(init?: Partial<LoadAssessment>) { (Object as any).assign(this, init); }
    public createResponse() { return new LoadAssessmentResponse(); }
    public getTypeName() { return 'LoadAssessment'; }
}

// @Route("/api/start-assessment", "POST")
export class StartAssessment implements IReturn<StartAssessmentResponse>, IPost
{
    public attempts: UserModuleAttempt[];

    public constructor(init?: Partial<StartAssessment>) { (Object as any).assign(this, init); }
    public createResponse() { return new StartAssessmentResponse(); }
    public getTypeName() { return 'StartAssessment'; }
}

// @Route("/api/end-assessment", "POST")
export class EndAssessment implements IReturn<EndAssessmentResponse>, IPost
{
    public attempts: UserModuleAttempt[];

    public constructor(init?: Partial<EndAssessment>) { (Object as any).assign(this, init); }
    public createResponse() { return new EndAssessmentResponse(); }
    public getTypeName() { return 'EndAssessment'; }
}

export class ResetMostRecentAttempts implements IReturn<ResetMostRecentAttemptsResponse>, IPost
{
    public constructor(init?: Partial<ResetMostRecentAttempts>) { (Object as any).assign(this, init); }
    public createResponse() { return new ResetMostRecentAttemptsResponse(); }
    public getTypeName() { return 'ResetMostRecentAttempts'; }
}

export class AssignUserTraining implements IReturn<AssignUserTrainingResponse>, IPost
{
    public userNames: string[];
    public moduleIds: number[];
    public dueDate: string;
    public repeat: boolean;

    public constructor(init?: Partial<AssignUserTraining>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignUserTrainingResponse(); }
    public getTypeName() { return 'AssignUserTraining'; }
}



// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

