



/*
 * --------------------------------------------------------------------------------
 * Enumerations
 * --------------------------------------------------------------------------------
 */

export enum RequestState {
    None = 0,
    Pending = 1,
    Success = 2,
    Failure = 3,
    Cancel = 4
}

