import React, { useEffect } from 'react';
import { Switch, RouteComponentProps, Route, Redirect } from 'react-router-dom'
import { createRoutes } from '../../routes';
import routes from '../../routes/routes';
import { makeStyles, Theme, Link, Tooltip } from '@material-ui/core';
import { MenuBar } from '../../components/MenuBar';
import { Footer } from '../../components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { IStoreState } from '../../store/createStore';
import { personActions } from '../../store/reducers/person';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faHome, faClipboardCheck, faTasks, faUser } from '@fortawesome/free-solid-svg-icons';
import { routerActions } from 'connected-react-router';
import ConditionalRoute from '../../components/ConditionalRoute';
import Home from './Home';
import UserModules from './UserModules';
import TrainingAssignment from './TrainingAssignment';


interface IWrapperrProps extends RouteComponentProps {
}

interface IWrapperStyleProps {

}

const bctLogo = require("../../assets/images/bctLogo.png");

const useStyles = makeStyles<Theme,IWrapperStyleProps> ((theme: Theme) => ({

    main: {
        backgroundColor: theme.palette.grey[200],
        height: "100vh",
        width: "100%",
        display: 'flex',
        flexDirection: 'column'
        //position: "absolute"
    },
    header: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        paddingLeft: "17%",
        paddingRight: "17%",
        justifyContent: "space-between",
        backgroundColor: "#FFFFFF"
    },
    headerImageContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& h1": {
            color: theme.palette.primary.main,
            fontSize: "32px",
            fontWeight: "700",
            marginTop: "0",
            marginBottom: "0",
            marginLeft: theme.spacing(4)
        }
    },
    image: {
        width: theme.spacing(17)
    },
    headerTextContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        fontFamily: "Roboto, sans-serif",
        justifyContent: "center",
    },
    body: {
        width: "100%"
    },
    userName: {
        fontSize: "20px"
    },
    link: {
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.secondary.main
        }
    }

}))

const Wrapper: React.FunctionComponent<IWrapperrProps> = (props) => {
    
    const classes = useStyles({});
    const authenticatedPerson = useSelector((state: IStoreState) => state.personState.authenticatedPerson)

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <div className={classes.headerImageContainer}>
                    <img className={classes.image} alt="BCT logo" src={bctLogo} />
                    <h1>Staff Training Administration</h1>
                </div>
                <div className={classes.headerTextContainer}>
                    <div className={classes.userName}>
                        {
                            authenticatedPerson ?
                                `${authenticatedPerson.firstName} ${authenticatedPerson.surname}`
                                : null
                        }
                        &nbsp; | &nbsp;
                            <Tooltip title="Staff Portal" aria-label="add" placement="bottom" >
                            <Link className={classes.link}
                                href={routes.staff.childRoutes?.home.path}
                            >
                                <FontAwesomeIcon className={classes.link} icon={faUser} fixedWidth />
                        </Link>
                        </Tooltip>
                        &nbsp; |
                        <Tooltip title="Log out" aria-label="add" placement="bottom" >
                            <Link style={{marginLeft: "10px"}}
                                href="/auth/logout"
                            >
                                <FontAwesomeIcon className={classes.link} icon={faSignOutAlt} fixedWidth />
                            </Link>
                        </Tooltip>
                    </div>
                </div>
                
            </div>
            <MenuBar
                admin={true}
            />
            <Switch>
                <Route path="/administration/home" component={Home} />
                <Route path="/administration/user-modules" component={UserModules} />
                <Route path="/administration/training-assignment" component={TrainingAssignment} />
                <Redirect path="/administration" to="/administration/home" />
            </Switch>
            <Footer />
            
        </div>
    );
}

export default Wrapper;