
import * as StaffTrainingDtos from '../../dtos/StaffTrainingDtos'
import { IRequestState } from '../../types/IRequestState'
import { RequestState } from '../../types/RequestState';

import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import { createGetRequest, createPostRequest } from '../../helpers/createRequest';
import { Epic, ofType, combineEpics } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import createAction from '../../helpers/createAction';
import { of } from 'rxjs';

interface IInitialUserModuleState {
    oustandingModules?: StaffTrainingDtos.UserModuleAttempt[] | null;
    completedModules?: StaffTrainingDtos.UserModuleAttempt[] | null;
    loadOustandingState: IRequestState;
    loadCompletedState: IRequestState;
    resetAttemptsResponseState: IRequestState;
    assignUserTrainingState: IRequestState;
}

export interface IUserModuleState {
    userModuleState: IInitialUserModuleState;
}

const typeNameSpace = '@@userModule'

export const types = {
    LOAD_OUSTANDING: `${typeNameSpace}/LOAD_OUSTANDING`,
    LOAD_OUSTANDING_SUCCESS: `${typeNameSpace}/LOAD_OUSTANDING_SUCCESS`,
    LOAD_OUSTANDING_FAILURE: `${typeNameSpace}/LOAD_OUSTANDING_FAILURE`,
    LOAD_COMPLETED: `${typeNameSpace}/LOAD_COMPLETED`,
    LOAD_COMPLETED_SUCCESS: `${typeNameSpace}/LOAD_COMPLETED_SUCCESS`,
    LOAD_COMPLETED_FAILURE: `${typeNameSpace}/LOAD_COMPLETED_FAILURE`,
    LOAD_ALL_COMPLETED: `${typeNameSpace}/LOAD_ALL_COMPLETED`,
    LOAD_ALL_COMPLETED_SUCCESS: `${typeNameSpace}/LOAD_ALL_COMPLETED_SUCCESS`,
    LOAD_ALL_COMPLETED_FAILURE: `${typeNameSpace}/LOAD_ALL_COMPLETED_FAILURE`,
    RESET_RECENT_ATTEMPTS: `${typeNameSpace}/RESET_RECENT_ATTEMPTS`,
    RESET_RECENT_ATTEMPTS_SUCCESS: `${typeNameSpace}/RESET_RECENT_ATTEMPTS_SUCCESS`,
    RESET_RECENT_ATTEMPTS_FAILURE: `${typeNameSpace}/RESET_RECENT_ATTEMPTS_FAILURE`,
    ASSIGN_TRAINING: `${typeNameSpace}/ASSIGN_TRAINING`,
    ASSIGN_TRAINING_SUCCESS: `${typeNameSpace}/ASSIGN_TRAINING_SUCCESS`,
    ASSIGN_TRAINING_FAILURE: `${typeNameSpace}/ASSIGN_TRAINING_FAILURE`,
    CLEAR: `${typeNameSpace}/CLEAR`,
};


export const initialState: IInitialUserModuleState = {
    oustandingModules: null,
    completedModules: null,
    loadOustandingState: {
        state: RequestState.None
    },
    loadCompletedState: {
        state: RequestState.None
    },
    resetAttemptsResponseState: {
        state: RequestState.None
    },
    assignUserTrainingState: {
        state: RequestState.None
    }
}

const userModuleReducer = createReducer<IInitialUserModuleState>({}, initialState);

export const userModuleActions = {

    loadOutstanding: createAction(types.LOAD_OUSTANDING,
        (includeQuestions: boolean) => ({ includeQuestions })),

    loadOutstandingSuccess: createAction(types.LOAD_OUSTANDING_SUCCESS,
        (response: StaffTrainingDtos.GetOutstandingUserModulesByAuthResponse) => ({ response })
    ),
    loadOutstandingFailure: createAction(types.LOAD_OUSTANDING_FAILURE,
        (response: StaffTrainingDtos.GetOutstandingUserModulesByAuthResponse) => ({ response })
    ),
    loadCompleted: createAction(types.LOAD_COMPLETED,
        (includeQuestions: boolean) => ({ includeQuestions })),

    loadCompletedSuccess: createAction(types.LOAD_COMPLETED_SUCCESS,
        (response: StaffTrainingDtos.GetCompletedUserModulesByAuthResponse) => ({ response })
    ),
    loadCompletedFailure: createAction(types.LOAD_COMPLETED_FAILURE,
        (response: StaffTrainingDtos.GetCompletedUserModulesByAuthResponse) => ({ response })
    ),
    loadAllCompleted: createAction(types.LOAD_ALL_COMPLETED,
        (includeQuestions: boolean | null,
        failOnly: boolean | null,
        userNames: string[],
        moduleIds: number[],
        startDate: string,
        endDate: string) => ({ includeQuestions, failOnly, userNames, moduleIds, startDate, endDate })),

    loadAllCompletedSuccess: createAction(types.LOAD_ALL_COMPLETED_SUCCESS,
        (response: StaffTrainingDtos.GetCompleteUserModulesResponse) => ({ response })
    ),
    loadAllCompletedFailure: createAction(types.LOAD_ALL_COMPLETED_FAILURE,
        (response: StaffTrainingDtos.GetCompleteUserModulesResponse) => ({ response })
    ),
    resetAttempts: createAction(types.RESET_RECENT_ATTEMPTS,
        () => ({})
    ),
    resetAttemptsSuccess: createAction(types.RESET_RECENT_ATTEMPTS_SUCCESS,
        (response: StaffTrainingDtos.ResetMostRecentAttemptsResponse) => ({ response })
    ),
    resetAttemptsFailure: createAction(types.RESET_RECENT_ATTEMPTS_FAILURE,
        (response: StaffTrainingDtos.ResetMostRecentAttemptsResponse) => ({ response })
    ),
    assignUserTraining: createAction(types.ASSIGN_TRAINING,
        (userNames: string[], moduleIds: number[], dueDate: string, repeat: boolean) => ({ userNames, moduleIds, dueDate, repeat })),

    assignUserTrainingSuccess: createAction(types.ASSIGN_TRAINING_SUCCESS,
        (response: StaffTrainingDtos.AssignUserTrainingResponse) => ({ response })
    ),
    assignUserTrainingFailure: createAction(types.ASSIGN_TRAINING_FAILURE,
        (response: StaffTrainingDtos.AssignUserTrainingResponse) => ({ response })
    ),
    clear: createAction(types.CLEAR,
        () => ({})
    )
}

userModuleReducer.on(userModuleActions.loadOutstanding, (state) => (
    update(
        state,
        {
            oustandingModules: {
                $set: null
            },
            loadOustandingState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )        
));

userModuleReducer.on(userModuleActions.loadOutstandingSuccess, (state, payload) => (
    update(
        state,
        {
            oustandingModules: {
                $set: payload.response.userModules
            },
            loadOustandingState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.loadOutstandingFailure, (state, payload) => (
    update(
        state,
        {
            oustandingModules: {
                $set: undefined
            },
            loadOustandingState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.loadCompleted, (state) => (
    update(
        state,
        {
            completedModules: {
                $set: null
            },
            loadCompletedState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.loadCompletedSuccess, (state, payload) => (
    update(
        state,
        {
            completedModules: {
                $set: payload.response.userModules
            },
            loadCompletedState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.loadCompletedFailure, (state, payload) => (
    update(
        state,
        {
            completedModules: {
                $set: undefined
            },
            loadCompletedState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.loadAllCompleted, (state) => (
    update(
        state,
        {
            completedModules: {
                $set: null
            },
            loadCompletedState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.loadAllCompletedSuccess, (state, payload) => (
    update(
        state,
        {
            completedModules: {
                $set: payload.response.userModules
            },
            loadCompletedState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.loadAllCompletedFailure, (state, payload) => (
    update(
        state,
        {
            completedModules: {
                $set: undefined
            },
            loadCompletedState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.resetAttempts, (state) => (
    update(
        state,
        {
            oustandingModules: {
                $set: null
            },
            resetAttemptsResponseState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.resetAttemptsSuccess, (state, payload) => (
    update(
        state,
        {
            oustandingModules: {
                $set: payload.response.userModules
            },
            resetAttemptsResponseState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.resetAttemptsFailure, (state, payload) => (
    update(
        state,
        {
            oustandingModules: {
                $set: undefined
            },
            resetAttemptsResponseState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.assignUserTraining, (state) => (
    update(
        state,
        {
            assignUserTrainingState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.assignUserTrainingSuccess, (state, payload) => (
    update(
        state,
        {
            assignUserTrainingState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.assignUserTrainingFailure, (state, payload) => (
    update(
        state,
        {
            assignUserTrainingState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

userModuleReducer.on(userModuleActions.clear, (state) => ({ ...initialState }));

export const userModuleApi = {
    loadOutstanding: createGetRequest(
        StaffTrainingDtos.GetOutstandingUserModulesByAuth,
        (includeQuestions: boolean) => ({
            includeQuestions: includeQuestions
        })
    ),
    loadCompleted: createGetRequest(
        StaffTrainingDtos.GetCompletedUserModulesByAuth,
        (includeQuestions: boolean) => ({
            includeQuestions: includeQuestions
        })
    ),
    loadAllCompleted: createGetRequest(
        StaffTrainingDtos.GetCompleteUserModules,
        (includeQuestions: boolean | null,
            failOnly: boolean | null,
            userNames: string[],
            moduleIds: number[],
            startDate: string,
            endDate: string
        ) => ({
                includeQuestions: includeQuestions,
                failOnly: failOnly,
                userNames: userNames,
                moduleIds: moduleIds,
                startDate: startDate,
                endDate: endDate
        })
    ),
    updateUsersResponse: createPostRequest(
        StaffTrainingDtos.UpdateUsersResponse,
        (userResponse: StaffTrainingDtos.UserQuestionResponse) => ({
            userResponse: userResponse
        })
    ),
    resetAttempts: createPostRequest(
        StaffTrainingDtos.ResetMostRecentAttempts,
        () => ({})
    ),
    assignUserTraining: createPostRequest(
        StaffTrainingDtos.AssignUserTraining,
        (userNames: string[],
            moduleIds: number[],
            dueDate: string,
            repeat: boolean
        ) => ({
            userNames: userNames,
            moduleIds: moduleIds,
            dueDate: dueDate,
            repeat: repeat
        })
    ),
}


export const loadOustandingEpic: Epic<ReturnType<typeof userModuleActions.loadOutstanding>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(userModuleActions.loadOutstanding.getType()),
    mergeMap(action =>
        userModuleApi.loadOutstanding(action.payload.includeQuestions)
            .pipe(
                mergeMap(response =>
                    of(
                        userModuleActions.loadOutstandingSuccess(response)
                    )
                ),
                catchError(e => of(userModuleActions.loadOutstandingFailure(e)))
            )
    )
);

export const loadCompletedEpic: Epic<ReturnType<typeof userModuleActions.loadCompleted>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(userModuleActions.loadCompleted.getType()),
    mergeMap(action =>
        userModuleApi.loadCompleted(action.payload.includeQuestions)
            .pipe(
                mergeMap(response =>
                    of(
                        userModuleActions.loadCompletedSuccess(response)
                    )
                ),
                catchError(e => of(userModuleActions.loadCompletedFailure(e)))
            )
    )
);

export const loadAllCompletedEpic: Epic<ReturnType<typeof userModuleActions.loadAllCompleted>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(userModuleActions.loadAllCompleted.getType()),
    mergeMap(action =>
        userModuleApi.loadAllCompleted(
            action.payload.includeQuestions,
            action.payload.failOnly,
            action.payload.userNames,
            action.payload.moduleIds,
            action.payload.startDate,
            action.payload.endDate
        )
            .pipe(
                mergeMap(response =>
                    of(
                        userModuleActions.loadAllCompletedSuccess(response)
                    )
                ),
                catchError(e => of(userModuleActions.loadAllCompletedFailure(e)))
            )
    )
);

export const resetRecentTrainingEpic: Epic<ReturnType<typeof userModuleActions.resetAttempts>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(userModuleActions.resetAttempts.getType()),
    mergeMap(action =>
        userModuleApi.resetAttempts()
            .pipe(
                mergeMap(response =>
                    of(
                        userModuleActions.resetAttemptsSuccess(response)
                    )
                ),
                catchError(e => of(userModuleActions.resetAttemptsFailure(e)))
            )
    )
);

export const assignUserTrainingEpic: Epic<ReturnType<typeof userModuleActions.assignUserTraining>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(userModuleActions.assignUserTraining.getType()),
    mergeMap(action =>
        userModuleApi.assignUserTraining(action.payload.userNames, action.payload.moduleIds, action.payload.dueDate, action.payload.repeat)
            .pipe(
                mergeMap(response =>
                    of(
                        userModuleActions.assignUserTrainingSuccess(response)
                    )
                ),
                catchError(e => of(userModuleActions.assignUserTrainingFailure(e)))
            )
    )
);

export const userModuleEpics = combineEpics(
    loadOustandingEpic,
    loadCompletedEpic,
    loadAllCompletedEpic,
    resetRecentTrainingEpic,
    assignUserTrainingEpic
);

export default userModuleReducer;