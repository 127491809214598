
import * as StaffTrainingDtos from '../../dtos/StaffTrainingDtos'
import { IRequestState } from '../../types/IRequestState'
import { RequestState } from '../../types/RequestState';

import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import { createGetRequest, createPostRequest } from '../../helpers/createRequest';
import { Epic, ofType, combineEpics } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import createAction from '../../helpers/createAction';
import { of } from 'rxjs';

interface IInitialAssessmentState {
    assessableModules?: StaffTrainingDtos.UserModuleAttempt[] | null;
    loadAssessableModulesState: IRequestState;
    beginAssessmentState: IRequestState;
    endAssessmentState: IRequestState;
    updateQuestionResponseState: IRequestState;
}

export interface IAssessmentState {
    assessmentState: IInitialAssessmentState;
}

const typeNameSpace = '@@assessment'

export const types = {
    LOAD: `${typeNameSpace}/LOAD`,
    LOAD_SUCCESS: `${typeNameSpace}/LOAD_SUCCESS`,
    LOAD_FAILURE: `${typeNameSpace}/LOAD_FAILURE`,
    BEGIN: `${typeNameSpace}/BEGIN`,
    BEGIN_SUCCESS: `${typeNameSpace}/BEGIN_SUCCESS`,
    BEGIN_FAILURE: `${typeNameSpace}/BEGIN_FAILURE`,
    END: `${typeNameSpace}/END`,
    END_SUCCESS: `${typeNameSpace}/END_SUCCESS`,
    END_FAILURE: `${typeNameSpace}/END_FAILURE`,
    UPDATE_QUESTION_RESPONSE: `${typeNameSpace}/UPDATE_QUESTION_RESPONSE`,
    UPDATE_QUESTION_RESPONSE_SUCCESS: `${typeNameSpace}/UPDATE_QUESTION_RESPONSE_SUCCESS`,
    UPDATE_QUESTION_RESPONSE_FAILURE: `${typeNameSpace}/UPDATE_QUESTION_RESPONSE_FAILURE`,
    CLEAR: `${typeNameSpace}/CLEAR`,
};


export const initialState: IInitialAssessmentState = {
    assessableModules: null,
    loadAssessableModulesState: {
        state: RequestState.None
    },
    beginAssessmentState: {
        state: RequestState.None
    },
    endAssessmentState: {
        state: RequestState.None
    },
    updateQuestionResponseState: {
        state: RequestState.None
    }
}

const assessmentReducer = createReducer<IInitialAssessmentState>({}, initialState);

export const assessmentActions = {

    loadAssessable: createAction(types.LOAD,
        () => ({})
    ),
    loadAssessableSuccess: createAction(types.LOAD_SUCCESS,
        (response: StaffTrainingDtos.LoadAssessmentResponse) => ({ response })
    ),
    loadAssessableFailure: createAction(types.LOAD_FAILURE,
        (response: StaffTrainingDtos.LoadAssessmentResponse) => ({ response })
    ),
    beginAssessment: createAction(types.BEGIN,
        (attempts: StaffTrainingDtos.UserModuleAttempt[]) => ({ attempts })
    ),
    beginAssessmentSuccess: createAction(types.BEGIN_SUCCESS,
        (response: StaffTrainingDtos.StartAssessmentResponse) => ({ response })
    ),
    beginAssessmentFailure: createAction(types.BEGIN_FAILURE,
        (response: StaffTrainingDtos.StartAssessmentResponse) => ({ response })
    ),
    endAssessment: createAction(types.END,
        (attempts: StaffTrainingDtos.UserModuleAttempt[], status: StaffTrainingDtos.AssessmentStatus) => ({ attempts, status })
    ),
    endAssessmentSuccess: createAction(types.END_SUCCESS,
        (response: StaffTrainingDtos.EndAssessmentResponse) => ({ response })
    ),
    endAssessmentFailure: createAction(types.END_FAILURE,
        (response: StaffTrainingDtos.EndAssessmentResponse) => ({ response })
    ),
    updateQuestionResponse: createAction(types.UPDATE_QUESTION_RESPONSE,
        (userResponse: StaffTrainingDtos.UserQuestionResponse) => ({ userResponse })),

    updateQuestionResponseSuccess: createAction(types.UPDATE_QUESTION_RESPONSE_SUCCESS,
        (response: StaffTrainingDtos.UpdateUsersResponseResponse) => ({ response })
    ),
    updateQuestionResponseFailure: createAction(types.UPDATE_QUESTION_RESPONSE_FAILURE,
        (response: StaffTrainingDtos.UpdateUsersResponseResponse) => ({ response })
    ),
    clear: createAction(types.CLEAR,
        () => ({})
    )
}

assessmentReducer.on(assessmentActions.loadAssessable, (state) => (
    update(
        state,
        {
            assessableModules: {
                $set: undefined
            },
            loadAssessableModulesState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )        
));

assessmentReducer.on(assessmentActions.loadAssessableSuccess, (state, payload) => (
    update(
        state,
        {
            assessableModules: {
                $set: payload.response.userModuleAttempts
            },
            loadAssessableModulesState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

assessmentReducer.on(assessmentActions.loadAssessableFailure, (state, payload) => (
    update(
        state,
        {
            assessableModules: {
                $set: undefined
            },
            loadAssessableModulesState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

assessmentReducer.on(assessmentActions.beginAssessment, (state) => (
    update(
        state,
        {
            beginAssessmentState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

assessmentReducer.on(assessmentActions.beginAssessmentSuccess, (state, payload) => (
    update(
        state,
        {
            beginAssessmentState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

assessmentReducer.on(assessmentActions.beginAssessmentFailure, (state, payload) => (
    update(
        state,
        {
            beginAssessmentState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

assessmentReducer.on(assessmentActions.endAssessment, (state) => (
    update(
        state,
        {
            assessableModules: {
                $set: undefined
            },
            endAssessmentState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

assessmentReducer.on(assessmentActions.endAssessmentSuccess, (state, payload) => {

    return update(
        state,
        {
            assessableModules: {
                $set: payload.response.userModuleAttempts
            },
            endAssessmentState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
});

assessmentReducer.on(assessmentActions.endAssessmentFailure, (state, payload) => (
    update(
        state,
        {
            endAssessmentState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

assessmentReducer.on(assessmentActions.updateQuestionResponse, (state) => (
    update(
        state,
        {
            updateQuestionResponseState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

assessmentReducer.on(assessmentActions.updateQuestionResponseSuccess, (state, payload) => {

    let userModules = state.assessableModules;
    let attemptIndex = userModules?.findIndex(m => m.id === payload.response.userResponse?.attemptId);
    let responseIndex: number | undefined = -1;
    let attemptResponses: StaffTrainingDtos.UserQuestionResponse[] | undefined;

    if (attemptIndex !== undefined && userModules) {
        responseIndex = userModules[attemptIndex]?.userQuestionResponses?.findIndex(r => r.id === payload.response.userResponse?.id);
        attemptResponses = userModules[attemptIndex]?.userQuestionResponses
    }

    let userResponses: StaffTrainingDtos.UserQuestionResponse[] | undefined = []; 
    
    if (responseIndex === -1 && attemptIndex !== undefined && userModules && attemptResponses) {
        userResponses = attemptResponses;
        if (userResponses && payload.response.userResponse) {
            userResponses.push(payload.response.userResponse)
        }
    }

    if (attemptIndex !== undefined && responseIndex !== undefined && responseIndex >= 0 && payload.response.userResponse) {
        return update(
            state,
            {
                assessableModules: {
                    [attemptIndex]: {
                        userQuestionResponses: {
                            [responseIndex]: {
                                $set: payload.response.userResponse
                            }
                        }
                    }
                },
                updateQuestionResponseState: {
                    $set: {
                        state: RequestState.Success
                    }
                }
            }
        )
    } else if (userResponses && attemptIndex !== undefined) {
        return update(
            state,
            {
                assessableModules: {
                    [attemptIndex]: {
                        userQuestionResponses: {
                            $set: userResponses
                        }
                    }
                },
                updateQuestionResponseState: {
                    $set: {
                        state: RequestState.Success
                    }
                }
            }
        )
    } else {
         return update(
             state,
             {
                 updateQuestionResponseState: {
                     $set: {
                         state: RequestState.Success
                     }
                 }
             }
         )
     }
     
   
});

assessmentReducer.on(assessmentActions.updateQuestionResponseFailure, (state, payload) => (
    update(
        state,
        {
            updateQuestionResponseState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

assessmentReducer.on(assessmentActions.clear, (state) => ({ ...initialState }));

export const assessmentApi = {
    loadAssessable: createGetRequest(
        StaffTrainingDtos.LoadAssessment,
        () => ({ })
    ),
    beginAssessment: createPostRequest(
        StaffTrainingDtos.StartAssessment,
        (attempts: StaffTrainingDtos.UserModuleAttempt[]) => ({
            attempts: attempts
        })
    ),
    endAssessment: createPostRequest(
        StaffTrainingDtos.EndAssessment,
        (attempts: StaffTrainingDtos.UserModuleAttempt[], status: StaffTrainingDtos.AssessmentStatus) => ({
            attempts: attempts,
            status: status
        })
    ),
    updateUsersResponse: createPostRequest(
        StaffTrainingDtos.UpdateUsersResponse,
        (usersResponse: StaffTrainingDtos.UserQuestionResponse) => ({
            userResponse: usersResponse
        })
    ),
}


export const loadAssessableEpic: Epic<ReturnType<typeof assessmentActions.loadAssessable>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(assessmentActions.loadAssessable.getType()),
    mergeMap(action =>
        assessmentApi.loadAssessable()
            .pipe(
                mergeMap(response =>
                    of(
                        assessmentActions.loadAssessableSuccess(response)
                    )
                ),
                catchError(e => of(assessmentActions.loadAssessableFailure(e)))
            )
    )
);

export const beginAssessmentEpic: Epic<ReturnType<typeof assessmentActions.beginAssessment>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(assessmentActions.beginAssessment.getType()),
    mergeMap(action =>
        assessmentApi.beginAssessment(action.payload.attempts)
            .pipe(
                mergeMap(response =>
                    of(
                        assessmentActions.beginAssessmentSuccess(response)
                    )
                ),
                catchError(e => of(assessmentActions.beginAssessmentFailure(e)))
            )
    )
);

export const endAssessmentEpic: Epic<ReturnType<typeof assessmentActions.endAssessment>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(assessmentActions.endAssessment.getType()),
    mergeMap(action =>
        assessmentApi.endAssessment(action.payload.attempts, action.payload.status)
            .pipe(
                mergeMap(response =>
                    of(
                        assessmentActions.endAssessmentSuccess(response)
                    )
                ),
                catchError(e => of(assessmentActions.endAssessmentFailure(e)))
            )
    )
);

export const updateUsersResponseEpic: Epic<ReturnType<typeof assessmentActions.updateQuestionResponse>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(assessmentActions.updateQuestionResponse.getType()),
    mergeMap(action =>
        assessmentApi.updateUsersResponse(action.payload.userResponse)
            .pipe(
                mergeMap(response =>
                    of(
                        assessmentActions.updateQuestionResponseSuccess(response)
                    )
                ),
                catchError(e => of(assessmentActions.updateQuestionResponseFailure(e)))
            )
    )
);

export const assessmentEpics = combineEpics(
    loadAssessableEpic,
    beginAssessmentEpic,
    endAssessmentEpic,
    updateUsersResponseEpic,
);

export default assessmentReducer;