
import * as StaffTrainingDtos from '../../dtos/StaffTrainingDtos'
import { IRequestState } from '../../types/IRequestState'
import { RequestState } from '../../types/RequestState';

import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import { createGetRequest } from '../../helpers/createRequest';
import { Epic, ofType, combineEpics } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import createAction from '../../helpers/createAction';
import { of } from 'rxjs';

interface IInitialModuleState {
    data?: StaffTrainingDtos.Module[] | null;
    loadState: IRequestState;
}

export interface IModuleState {
    moduleState: IInitialModuleState;
}

const typeNameSpace = '@@module'

export const types = {
    LOAD: `${typeNameSpace}/LOAD`,
    LOAD_SUCCESS: `${typeNameSpace}/LOAD_SUCCESS`,
    LOAD_FAILURE: `${typeNameSpace}/LOAD_FAILURE`,
    CLEAR: `${typeNameSpace}/CLEAR`,
};


export const initialState: IInitialModuleState = {
    data: null,
    loadState: {
        state: RequestState.None
    }
}

const moduleReducer = createReducer<IInitialModuleState>({}, initialState);

export const moduleActions = {

    load: createAction(types.LOAD, () => ({})),

    loadSuccess: createAction(types.LOAD_SUCCESS,
        (response: StaffTrainingDtos.GetModulesResponse) => ({ response })
    ),
    loadFailure: createAction(types.LOAD_FAILURE,
        (response: StaffTrainingDtos.GetModulesResponse) => ({ response })
    ),
}

moduleReducer.on(moduleActions.load, (state) => (
    update(
        state,
        {
            data: {
                $set: null
            },
            loadState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )        
));

moduleReducer.on(moduleActions.loadSuccess, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.response.modules
            },
            loadState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

moduleReducer.on(moduleActions.loadFailure, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

export const moduleApi = {
    load: createGetRequest(
        StaffTrainingDtos.GetModules,
        () => ({})
    ),
}


export const loadEpic: Epic<ReturnType<typeof moduleActions.load>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(moduleActions.load.getType()),
    mergeMap(action =>
        moduleApi.load()
            .pipe(
                mergeMap(response =>
                    of(
                        moduleActions.loadSuccess(response)
                    )
                ),
                catchError(e => of(moduleActions.loadFailure(e)))
            )
    )
);

export const moduleEpics = combineEpics(loadEpic);

export default moduleReducer;