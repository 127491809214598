/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

import { useEffect } from "react";
import React from "react";

/*
 * --------------------------------------------------------------------------------
 * This file contains helper methods for an authenticated person object.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

export const useDeferredValue = <T,>(v: T) => {
    const [value, setValue] = React.useState(v);
    //const timerRef = React.useRef<number | undefined>();

    useEffect(() => {

        let unmounted = false;

        const id = window.setTimeout(() => {
            if (!unmounted) {
                setValue(v);
            }
        }, 500);

        return () => {
            unmounted = true;
            clearTimeout(id);
        }
    }, [v])

    return value;
};