import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';

interface ILinkProps {
    isAdmin?: boolean;
}

interface ILinkStyleProps {
    isAdmin?: boolean;
}

const useStyles = makeStyles<Theme, ILinkStyleProps>((theme: Theme) => ({
    footer: {
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        height: theme.spacing(4),
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.common.white,
    },
    footOuter: {
        display: "flex",
        justifyContent: "center",
    }
}))


export const Footer: React.FunctionComponent<ILinkProps> = ({ isAdmin }) => {

    const classes = useStyles({ isAdmin: isAdmin });

    return (
        <div className={classes.footOuter}>
            <div className={classes.footer}>
                    Copyright &#169; 2020 ANZ Breast Cancer Trials Group Ltd 
            </div>
        </div>
    );
}

export default Footer;